import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled, { css } from 'styled-components';

import { Icon, Link, UiEmbed } from '@powdr/components';
import { BlockTypes, Breakpoints, ThemeProps as Prop } from '@powdr/constants';
import { AppContext } from '@powdr/context/app-context';
import { ContentBlockType } from '@powdr/model';
import { themeUtil, rgbaTransparencyToHex, getDateInfo } from '@powdr/utils';

const CPNT = BlockTypes.REVEAL_VERTICAL_FULL;

// styled components
const ContentBlockRevealVerticalFull = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  z-index: 17;

  .cb-title {
    margin-bottom: 15px;
  }

  .cta-w-tool-reveal {
    height: 100%;
  }

  .cta-reveal-wrapper {
    display: block;
    position: absolute;
    bottom: 0;
    text-align: left;
    width: 100%;
    height: 100%;
    z-index: 10;
    overflow: hidden;

    .cta-reveal-inner {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile) + rgbaTransparencyToHex(0.75)};
      position: absolute;
      padding: 25px;
      width: 100%;
      height: 135px;
      bottom: 0;

      .cta-content {

        .date-text {
          font-size: ${({ theme }) => theme.FontSize.REG40};
          margin-bottom: 5px;
          text-align: left;
        }

        p {
          opacity: 0;
          visibility: hidden;
          line-height: 21px;
        }
      }
    }

    &:hover:not(.overlay), &:focus-within {
      .cta-reveal-inner {
        transition: height 0.9s ease-in-out;
        height: 1000px;
        max-height: 100%;

        .cta-content {
          h3 {
            padding-bottom: 25px;

            // remove title on click for mobile to show only description/summary
            ${Breakpoints.mobile(css`
              padding-bottom: 10px;
              display: none;
            `)}
          }

          p {
            opacity: 1;
            transition: opacity 0.4s 0.4s ease-in-out;
            visibility: visible;
          }
        }
      }

      .button-bar {
        transition: bottom 0.5s ease-in-out;
        bottom: 0;
      }
    }
  }
`;

const TriangleElement = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: 1;
  border-left-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile) + rgbaTransparencyToHex(0.75)};
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-style: solid;
  border-width: 0 0 115px 115px;
  ${Breakpoints.mobile(css`
    border-width: 0 0 65px 65px;
  `)}
`;

const AccentIconWrapper = styled.div`
  position: absolute;
  top: 13px;
  left: 8px;
  z-index: 1;

  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
  }

  ${Breakpoints.mobile(css`
    top: 6px;
    left: 3px;
  `)}
`;

const ButtonBar = styled.div`
  vertical-align: middle;
  position: absolute;
  width: 100%;
  bottom: -62px;
  overflow: hidden;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
`;

/* const StyledSocialShareButtons = styled(SocialShareButtons)`
  display: inline-block;
  width: auto;
  height: auto;
  overflow: hidden;
  margin: 0;
  padding-right: 20px;
  float: right;

  li {
    display: inline-block;
    margin-bottom: 0;
  }

  ${Breakpoints.smaller(css`
    padding-right: 8px;
  `)}
`; */

const LinkWrapper = styled.div`
  float: left;
  width: auto;
  height: auto;
  overflow: hidden;
  margin: 21px 0 19px 25px;

  ${Breakpoints.smaller(css`
    margin-left: 12px;
  `)}

  ${Breakpoints.smallInbetween(css`
    margin-top: 21px;
    margin-bottom: 19px;
  `)}

  ${Breakpoints.mobile(css`
    margin-top: 23px;
    margin-bottom: 21px;
  `)}

  .cb-link {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: none;
    font-size: ${({ theme }) => theme.FontSize.REG20};
    text-transform: uppercase;
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};

    .cb-link-icon {
      margin-left: 7px;

      svg {
        fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
      }

      ${Breakpoints.smallInbetween(css`
        display: none;
      `)}
    }

    &:hover, &:focus {
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};

      svg {
        fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      }
    }

    &:focus {
      outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
      outline-offset: -2px;
    }
  }
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  position: static;
`;

const Image = styled.img`
  position: static;
`;

export const RevealVerticalFull = ({
  title, copy, isOverlayEnabled, image, colorProfile, blockType, ariaLabel,
  buttonText, buttonLink, buttonTarget, buttonClick,
  accentIconName, videoId, date, endDate,
  isStaticImageLink,
}) => {
  const { isMobile } = useContext(AppContext);
  const dateInfo = getDateInfo(date, endDate, blockType);

  return (
    <ContentBlockRevealVerticalFull
      className={`content-block-${blockType} `}
      colorProfile={colorProfile}
    >
      <div className="cta-w-tool-reveal">
        {(accentIconName) && (
          <>
            <TriangleElement colorProfile={colorProfile} />
            <AccentIconWrapper colorProfile={colorProfile}>
              <Icon name={accentIconName} height={(isMobile) ? '25' : '45'} />
            </AccentIconWrapper>
          </>
        )}
        {(image && !isStaticImageLink) && <StyledGatsbyImage image={image.src} alt={image?.altText} />}
        {(image && isStaticImageLink) && <Image src={image.src} alt={image?.altText} />}
        <div className={classNames('cta-reveal-wrapper', { overlay: isOverlayEnabled })}>
          <div className="cta-reveal-inner">
            <div className="cta-content">
              {(date) && <h4 className="date-text">{ReactHtmlParser(dateInfo.full)}</h4>}
              <h3 className="cb-title">{title}</h3>
              <p className="cb-copy">{copy}</p>
            </div>
          </div>
          <ButtonBar colorProfile={colorProfile} className="button-bar">
            <LinkWrapper>
              <Link
                className="cb-link button"
                href={buttonLink}
                target={buttonTarget}
                onClick={() => buttonClick()}
                aria-label={ariaLabel}
              >
                {buttonText}
                <Icon className="cb-link-icon" name="ui-arrow-thin-right" width="20" height="20" />
              </Link>
            </LinkWrapper>
            {/* <StyledSocialShareButtons title={title} copy={copy} size="32" url={buttonLink}/> */}
          </ButtonBar>
        </div>
        {(videoId) && <UiEmbed videoId={videoId} />}
      </div>
    </ContentBlockRevealVerticalFull>
  );
};

RevealVerticalFull.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
