import styled, { css } from 'styled-components';

import { HeaderLogo, SeasonToggle, Link } from '@powdr/components';
import {
  ThemeProps as Prop, Components,
} from '@powdr/constants';
import { componentColor, themeUtil } from '@powdr/utils';

const CPNT = Components.MOBILE_NAV;

export const StyledMobileDrawer = styled.div`
  transform: translateX(100%);
  position: fixed;
  top: 0;
  z-index: 80; // places drawer on top of header
  height: 100%;
  width: 100vw;
  background-color: ${() => componentColor(Prop.MAIN_NAV_BG, CPNT, Prop.BACKGROUND)};
  transition: transform 0.5s ease;

  ${({ isMobileDrawerOpen }) => isMobileDrawerOpen && css`
    transform: translateX(0);
  `}

  ${({ isMobileThirdLevelOpen }) => isMobileThirdLevelOpen && css`
    .main-navigation-list {
      position: absolute;
      top: auto;
      width: 100%;
    }
  `}
`;

export const MobileDrawerHeader = styled.header`
  display: flex;
  flex-direction: column;
`;

export const MainHeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${({ $height }) => `${$height}px`};
  background-color: ${() => componentColor(Prop.LOGO_BG, CPNT, Prop.BACKGROUND)};
`;

export const PropertyLogo = styled(HeaderLogo)`
  a {
    background-color: ${() => componentColor(Prop.LOGO_BG, CPNT, Prop.BACKGROUND)};

    svg {
      height: 100%;
      width: 100%;
    }
  }
`;

export const DrawerSeasonToggleWrapper = styled.div``;

export const DrawerSeasonToggle = styled(SeasonToggle)``;

export const DrawerCloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  background-color: ${() => componentColor(Prop.CLOSE_BTN_BG, CPNT, Prop.BTN_BG)};

  svg {
    fill: ${() => componentColor(Prop.CLOSE_BTN_ICON, CPNT, Prop.BTN_TXT)};
  }

  &:hover {
    background-color: ${() => componentColor(Prop.CLOSE_BTN_BG, CPNT, Prop.BTN_BG)};

    svg {
      fill: ${() => componentColor(Prop.CLOSE_BTN_ICON, CPNT, Prop.BTN_TXT)};
    }
  }
`;

export const MobileDrawerBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${({ height }) => `calc(100% - ${height}px)`};
`;

export const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: ${({ maxHeightOffset }) => `calc(100vh - ${maxHeightOffset}px - ${maxHeightOffset}px)`};
`;

export const MobileDrawerButton = styled(Link)`
  ${({ theme }) => theme.fonts.PRI_BDY_MED};
  font-size: ${({ theme }) => theme.FontSize.REG50};
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${({ height }) => `height: ${height}px;`};
  padding: 0 35px;
  border-top: 1px solid ${() => componentColor(Prop.MAIN_NAV_BORDER, CPNT, Prop.BORDER)};
  color: ${() => componentColor(Prop.DRAWER_BTN_TXT, CPNT, Prop.LINK_TXT)};
  background-color: ${() => componentColor(Prop.DRAWER_BTN_BG, CPNT, Prop.BACKGROUND)};

  svg {
    fill: ${() => componentColor(Prop.DRAWER_BTN_TXT, CPNT, Prop.LINK_TXT)};
  }
`;

export const FooterButtons = styled.div`
  width: 100%;
`;

export const DorComponents = styled.div`
  padding: 25px;

  .grid {
    li {
      width: 50%;
      padding: 10px;
    }
  }
`;

export const PromotionDrawer = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  transform: translateY(100%);
  transition: transform 0.5s ease;
  background-color: ${() => componentColor(Prop.MAIN_NAV_BG, CPNT, Prop.BACKGROUND)};

  &.drawer-open {
    transform: translateY(0);
  }
`;

const PromotionDrawerButtonStyles = css`
  ${({ theme }) => theme.fonts.SEC_HDR_BLK};
  display: flex;
  width: 100%;
  height: ${({ height }) => `${height}px`};
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  font-size: ${({ theme }) => theme.FontSize.MED20};
  text-transform: uppercase;
  border-top: 1px solid ${() => componentColor(Prop.MAIN_NAV_BORDER, CPNT, Prop.BORDER)};
  color: ${() => componentColor(Prop.PROMO_BTN_TXT, CPNT, Prop.LINK_TXT)};
  background-color: ${() => componentColor(Prop.PROMO_BTN_BG, CPNT, Prop.BACKGROUND)};
`;

export const PromotionDrawerOpenButton = styled.button`
  ${PromotionDrawerButtonStyles}
`;

export const PromotionDrawerCloseButton = styled.button`
  position: fixed;
  top: 0;
  right: 0;
  padding: 20px;
  background-color: ${() => componentColor(Prop.CLOSE_BTN_BG, CPNT, Prop.BTN_BG)};
  z-index: 10;

  svg {
    fill: ${() => componentColor(Prop.CLOSE_BTN_ICON, CPNT, Prop.BTN_TXT)};
  }
`;

export const PromotionDrawerLink = styled(Link)`
  ${PromotionDrawerButtonStyles}
`;
