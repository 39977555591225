import styled, { css } from 'styled-components';

import { EcommerceBanner, WidgetPromotions } from '@powdr/components';
import {
  ThemeProps as Prop, Components, Breakpoints,
} from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.HEADER;

export const StyledFeedContainer = styled.div`
  pointer-events: all;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  ${Breakpoints.mobile(css`
    height: ${({ headerHeightMobile }) => ((headerHeightMobile) ? `${headerHeightMobile}px` : '100%')};
    width: ${({ isDorFeedEnabled, mobileWeatherWidgetWidth }) => ((isDorFeedEnabled) ? `${mobileWeatherWidgetWidth}px` : '0')};
  `)}
  ${Breakpoints.desktopOnly(css`
    width: ${({ headerFeedContainer }) => ((headerFeedContainer) ? `${headerFeedContainer}px` : 'auto')};
  `)}

  .season-toggle {
    ${Breakpoints.desktopOnly(css`
      position: static;
      margin: 0 auto;
    `)}
    ${Breakpoints.mobile(css`display: none;`)}
  }
`;

export const FeedEcommerceBanner = styled(EcommerceBanner)`
  ${Breakpoints.mobile(css`
    display: none;
  `)}
`;

export const WidgetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

export const MobileDorDrawer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  padding: 0 15px 30px 30px;
  transition: transform 0.5s ease;
  transform: translateY(-100%);
  background-color: ${() => themeUtil(Prop.BACKGROUND, Components.MEGA_NAV)};
  z-index: 80;

  ${Breakpoints.desktopOnly(css`
    display: none;
  `)}

  &.open {
    transform: translateY(0)
  }
`;

export const FeedScrollContainer = styled.div`
  overflow-y: auto;
  max-height: 100vh;
  padding-right: 5px;
`;

export const DorDrawerCloseButton = styled.button`
  background-color: ${() => themeUtil(Prop.BTN_BG, CPNT)};
  padding: 20px;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;

  svg {
    fill: ${() => themeUtil(Prop.BTN_TXT, CPNT)};
  }

  &:hover {
    background-color: ${() => themeUtil(Prop.BTN_BG_HOVER, CPNT)};

    svg {
      fill: ${() => themeUtil(Prop.BTN_TXT_HOVER, CPNT)};
    }
  }
`;
