import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Breakpoints, Components,
} from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.COLLAPSE_PANEL;

export const StyledCollapsePanel = styled.section`
  padding: 30px 0;
`;

export const PanelHeader = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: ${({ isContainer }) => ((isContainer) ? '0 7.5%;' : '0px;')};
`;

export const HeaderLeft = styled.div``;

export const TitleText = styled.h3`
  color: ${({ colorProfile }) => themeUtil(Prop.HEADER, CPNT, colorProfile)};
  font-size: ${({ theme }) => theme.FontSize.MED10};
`;

export const CopyText = styled.p`
  margin-bottom: 0px;
  ${({ theme }) => theme.fonts.PRI_BDY_MED};
  font-size: ${({ theme }) => theme.FontSize.REG40};
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  ${Breakpoints.smallest(css`margin-bottom: 15px;`)}
`;

export const HeaderRight = styled.div``;

export const ExpandButton = styled.button`
  display: flex;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  padding: 15px 20px;
  border-radius: 25px;
  color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
  background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};

  .expand-icon {
    animation-direction: alternate;
    transition: transform 0.5s ease-in-out;
    transform: rotate(0deg);
    ${({ hasText }) => ((hasText) ? 'margin-left: 7px' : '')};

    &.open {
      transform: rotate(180deg);
    }
  }

  &:hover {
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};

    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
    }
  }

  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
  }
`;

export const PanelBodyWrapper = styled.section`
  ${({ isOpen }) => ((isOpen) ? 'display: block;' : 'display: none;')}
  ${({ isContainer }) => ((isContainer) ? 'padding: 0 7.5%;' : '')}
`;
