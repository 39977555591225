import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { useQueryParam, StringParam } from 'use-query-params';

import { Icon } from '@powdr/components';
import { DemoHeaderBanner } from '@powdr/components/demos';
import {
  Season, QueryParamNames, Components, ThemeProps as Prop,
} from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useSeason, useSeasonChange } from '@powdr/hooks';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.HEADER;

const StyledDemoSeasonToggle = styled.div`
  width: 100%;
  height: 100%;
`;

const ToggleSwitchContainer = styled.div`
  display: flex;
`;

const ToggleSwitchStyles = css`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
`;

const SummerToggle = styled.div`
  ${ToggleSwitchStyles}
  border-left: 1px solid ${() => themeUtil(Prop.BORDER, CPNT)};

  ${({ active }) => ((active) ? `
    color: ${themeUtil(Prop.LINK_TXT_HOVER, CPNT)};

    svg {
      fill: #ffcb00;
    }
    ` : `
    color: ${themeUtil(Prop.LINK_TXT, CPNT)};

    svg {
      fill: ${themeUtil(Prop.LINK_TXT, CPNT)};
    }`)}
`;

const WinterToggle = styled.div`
  ${ToggleSwitchStyles}

  ${({ active }) => ((active) ? `
    color: ${themeUtil(Prop.LINK_TXT_HOVER, CPNT)};

    svg {
      fill: ${themeUtil(Prop.LINK_TXT_HOVER, CPNT)};
    }
    ` : `
    color: ${themeUtil(Prop.LINK_TXT, CPNT)};

    svg {
      fill: ${themeUtil(Prop.LINK_TXT, CPNT)};
    }`)}
`;

const ToggleLabel = styled.div`
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.REG30};
  line-height: ${({ theme }) => theme.FontSize.REG30};
  padding-left: 5px;
`;

const ToggleIcon = styled(Icon)``;

export const DemoSeasonToggle = () => {
  const { uiSeason } = useContext(AppContext);
  const [seasonParam] = useQueryParam(QueryParamNames.SEASON, StringParam);
  const { handleSeasonChange } = useSeasonChange();

  return (
    <DemoHeaderBanner>
      <StyledDemoSeasonToggle
        onClick={
          () => (
            seasonParam === Season.SUMMER
              ? handleSeasonChange(Season.WINTER)
              : handleSeasonChange(Season.SUMMER)
          )
        }
      >
        <ToggleSwitchContainer>
          <WinterToggle active={uiSeason === Season.WINTER}>
            <ToggleIcon name="dor-snow" height="25" />
            <ToggleLabel>Winter</ToggleLabel>
          </WinterToggle>
          <SummerToggle active={uiSeason === Season.SUMMER}>
            <ToggleIcon name="dor-clear" height="25" />
            <ToggleLabel>Summer</ToggleLabel>
          </SummerToggle>
        </ToggleSwitchContainer>
      </StyledDemoSeasonToggle>
    </DemoHeaderBanner>

  );
};

DemoSeasonToggle.propTypes = {

};

DemoSeasonToggle.defaultProps = {

};
