import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components';
import { JumpToButtons } from '@powdr/components/demos';
import { FontFamily, FontSize, ThemeProps as Prop } from '@powdr/constants';
import { IconGroup } from '@powdr/constants/constants';
import { getFontStyles, importIcons, themeUtil } from '@powdr/utils';

const JumpToButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 5px solid ${({ colorProfile }) => themeUtil(Prop.BORDER, null, colorProfile)};
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, null, colorProfile)};
  padding: 25px;;
`;

const JumpToButtonsHeader = styled.h1`
  ${({ theme }) => theme.fonts.PRI_HDR_BLK};
  font-size: ${({ theme }) => theme.FontSize.MED30};
  margin-bottom: 25px;
`;

const GroupWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  scroll-margin-top: 210px;
`;

const IconGroupWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  border: 5px solid ${({ colorProfile }) => themeUtil(Prop.BORDER, null, colorProfile)};
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, null, colorProfile)};
  justify-content: space-around;
`;

const GroupTitle = styled.h1`
  ${({ theme }) => theme.fonts.PRI_HDR_BLK};
  font-size: ${({ theme }) => theme.FontSize.MED30};
  margin: 25px;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  align-items: center;
  justify-content: flex-end;

  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.ICON, null, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.ICON, null, colorProfile)};
  }
`;

const StyledIcon = styled(Icon)`
  max-height: ${({ maxHeight }) => `${maxHeight}px`};
`;

const IconTitle = styled.div`
  ${({ theme }) => theme.fonts.PRI_BDY_MED};
  font-size: ${({ theme }) => theme.FontSize.REG40};
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, null, colorProfile)};
  padding-top: 7px;
`;

export const IconsDemo = ({
  colorProfile,
}) => {
  const icons = { ...importIcons(require.context('../../icon/icons', true, /\.svg$/)) };
  const filterByGroup = (group) => Object.entries(icons)
    .filter(([key]) => key.startsWith(group))
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

  const iconGroups = [
    {
      sectionTitle: 'Content Icons',
      anchor: 'content',
      items: filterByGroup(IconGroup.CONTENT),
    },
    {
      sectionTitle: 'UI Icons',
      anchor: 'ui',
      items: filterByGroup(IconGroup.UI),
    },
    {
      sectionTitle: 'DOR Icons',
      anchor: 'dor',
      items: filterByGroup(IconGroup.DOR),
    },
    {
      sectionTitle: 'Status Icons',
      anchor: 'status',
      items: filterByGroup(IconGroup.STATUS),
    },
    {
      sectionTitle: 'Difficulty Level Icons',
      anchor: 'difficulty',
      items: filterByGroup(IconGroup.DIFFICULTY_LEVEL),
    },
    {
      sectionTitle: 'Feature Icons',
      anchor: 'feature',
      items: filterByGroup(IconGroup.FEATURE),
    },
    {
      sectionTitle: 'Lift Icons',
      anchor: 'lift',
      items: filterByGroup(IconGroup.LIFT),
    },
    {
      sectionTitle: 'Ecommerce Icons',
      anchor: 'ecomm',
      items: filterByGroup(IconGroup.ECOMM),
    },
    {
      sectionTitle: 'Property Icons',
      anchor: 'property',
      maxHeight: 100,
      width: 300,
      items: filterByGroup(IconGroup.PROPERTY),
    },
    {
      sectionTitle: 'Partner Icons',
      anchor: 'partner',
      maxHeight: 150,
      width: 150,
      items: filterByGroup(IconGroup.PARTNER),
    },
    {
      sectionTitle: 'Woodward Icons',
      anchor: 'woodward',
      width: 75,
      maxHeight: 75,
      items: filterByGroup(IconGroup.WOODWARD),
    },
    {
      sectionTitle: 'Social Media Icons',
      anchor: 'socials',
      items: filterByGroup(IconGroup.social),
    },
  ];

  return (
    <>
      <JumpToButtonsWrapper>
        <JumpToButtonsHeader>Jump to Icon Group</JumpToButtonsHeader>
        <JumpToButtons items={iconGroups.map((item) => item.anchor)} colorProfile={colorProfile} />
      </JumpToButtonsWrapper>
      {iconGroups.map((group) => (
        <GroupWrapper id={group.anchor}>
          <GroupTitle>{group.sectionTitle}</GroupTitle>
          <IconGroupWrapper colorProfile={colorProfile}>
            {Object.keys(group.items).map((icon) => (
              <IconWrapper colorProfile={colorProfile}>
                <StyledIcon
                  name={icon}
                  maxHeight={group.maxHeight || '50'}
                  width={group?.width || '40'}
                />
                <IconTitle colorProfile={colorProfile}>{icon}</IconTitle>
              </IconWrapper>
            ))}
          </IconGroupWrapper>
        </GroupWrapper>
      ))}

    </>
  );
};

IconsDemo.propTypes = {
  colorProfile: PropTypes.string.isRequired,
};

IconsDemo.defaultProps = {};
