import styled, { css } from 'styled-components';

import { Link } from '@powdr/components';
import { Breakpoints, ThemeProps as Prop, Components } from '@powdr/constants';
import { componentColor } from '@powdr/utils';

const CPNT = Components.FOOTER;

export const StyledSocialIconList = styled.div`
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  ${({ isEmailSignup }) => ((isEmailSignup) ? '' : 'margin-bottom: 20px;')}

  &.mobile-spacing {
    ${Breakpoints.mobile(css`
      padding-left: 10px;
    `)}

    @media only screen and (max-width: (529px - 1)) {
      padding-left: 0px;
    }
  }

  .social {
    display: inline-block;
    vertical-align: middle;
    height: auto;
    margin: 0;
    ${({ isEmailSignup }) => ((isEmailSignup) ? 'white-space: nowrap;' : '')}

    ${Breakpoints.smallest(css`
      display: block;
      width: 100%;
      text-align: center;
    `)}

    li {
      /* @include inline-block(middle); */
      margin-right: 15px;
      display: inline-block;

      a.btn {
        padding: 10px;
        background-color: ${() => componentColor(Prop.BTN_BG, CPNT)};
        transition: background-color 0.25s ease-out, fill 0.25s ease-out;

        &:hover, &:focus {
          transition: background-color 0.25s ease-out, fill 0.25s ease-out;
          background-color: ${() => componentColor(Prop.BTN_BG_HOVER, CPNT)};

          svg {
            fill: ${() => componentColor(Prop.BTN_TXT_HOVER, CPNT)};
          }
        }

        &:focus {
          outline: 2px solid ${() => componentColor(Prop.BTN_BG_HOVER, CPNT)};
          outline-offset: 2px;
        }

        svg {
          fill: ${() => componentColor(Prop.BTN_TXT, CPNT)};
        }
      }
    }
  }
`;

const sharedTextStyles = css`
  display: block;
  font-size: ${({ theme }) => theme.FontSize.REG30};
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  color: ${() => componentColor(Prop.LINK_TXT, Components.FOOTER_NAV)};
  transition: all 0.15s ease-in-out;
  line-height: 1.5;

  &:hover, &:active {
    color: ${() => componentColor(Prop.LINK_TXT_HOVER, Components.FOOTER_NAV)};
  }

  ${Breakpoints.large(css`
    font-size: ${({ theme }) => theme.FontSize.REG40};
  `)}

  ${Breakpoints.larger(css`
    font-size: ${({ theme }) => theme.FontSize.REG50} ;
  `)}
`;

export const Address = styled(Link)`
  ${sharedTextStyles}
`;

export const Email = styled(Link)`
  ${sharedTextStyles}
`;

export const PhoneNumber = styled(Link)`
  ${sharedTextStyles}
`;
