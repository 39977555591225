/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line import/no-unresolved
import { format, compareAsc, parse } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import {
  Icon, UiTable, Slider,
  UiColumn,
  CollapsePanelGroup,
  PriceRange,
} from '@powdr/components';
import { Alignment, DrupalComponents, VerticalPosition } from '@powdr/constants/constants';
import { AppContext } from '@powdr/context';
import {
  globalContentParser, fixCmsLinks, textFilter, cleanSliderData, cleanTableData,
  isEmpty,
} from '@powdr/utils';

import {
  StyledTemplatedContent, NewsStyleDate,
  DynamicTitle, DynamicBody, ShortDesc, NewsStyleImage,
  StartEndDate, DateContainer, RowSection,
  ContentContainer, SplitContentContainer,
  LocationHoursContainer, DynamicContactInfo,
  ContactInfoSection, DynamicHours, DynamicMenu,
  DynamicPrice,
} from './styles';

export const TemplatedContent = ({
  fields,
  isParentModal,
}) => {
  const { themeConfig: { modal } } = useContext(AppContext);
  const {
    title, body: bodyContent,
    field_date: dateString, field_end_date: endDateString,
    field_short_description: shortDesc,
    field_ui_table: tableContent, field_slider: sliderAPI, field_image: image,
    field_author: author, field_contact_name: contactName,
    field_contact_email: contactEmail, field_contact_phone: contactPhone,
    field_venue: venue, field_hours: hours, field_menu: menu, field_price_range: priceRange,
    field_location: location, field_location_details: locationDetails,
    field_phone_number: phoneNumber, field_phone_number_details: phoneNumberDetails,
    additionalContent, columnContent, sidebarContent, relationships: components,
  } = fields;
  const body = (bodyContent?.value) ? bodyContent.value : bodyContent;
  const slider = (components?.slider) ? components.slider : sliderAPI;
  const table = (tableContent?.rows) ? tableContent.rows : tableContent;
  const dropdowns = components?.dropdowns;
  const hoursContent = (hours?.value) ? hours.value : (hours && typeof hours === 'string') ? `<p>${hours}</p>` : null;
  const columnGallery = components?.columnGallery;

  const startDate = (dateString) ? parse(dateString, 'yyyy-MM-dd', new Date()) : null;
  const endDate = (endDateString) ? parse(endDateString, 'yyyy-MM-dd', new Date()) : null;
  const newsStyleModal = false;
  const isSplitContent = (slider || sidebarContent?.value);

  return (
    <StyledTemplatedContent
      $splitContent={isSplitContent}
      $isParentModal={isParentModal}
    >
      {(title || body || table || slider) && (
        <RowSection>
          <ContentContainer $splitContent={isSplitContent}>
            {(startDate && newsStyleModal) && <NewsStyleDate>{format(startDate, 'MMMM d, yyyy')}</NewsStyleDate>}
            {(title) && <DynamicTitle>{textFilter(title)}</DynamicTitle>}
            {(image && newsStyleModal) && <NewsStyleImage />}
            {((startDate || endDate) && !newsStyleModal) && (
              <DateContainer>
                <Icon name="content-calendar" width="25" height="25" />
                <StartEndDate>
                  {`${format(startDate, 'MMMM do yyyy')}`}
                  {(endDate && compareAsc(startDate, endDate) !== 0) ? ` - ${format(endDate, 'MMMM do yyyy')}` : ''}
                </StartEndDate>
              </DateContainer>
            )}
            {((location && location !== '') || (phoneNumber && phoneNumber !== '') || hoursContent) && (
              <LocationHoursContainer>
                {(hoursContent) && (
                  <DynamicHours>
                    {(priceRange?.length > 0) && <DynamicPrice max={4} value={priceRange?.length || 1} character="$" />}
                    {globalContentParser(fixCmsLinks(hoursContent))}
                  </DynamicHours>
                )}
                {(location !== '' || phoneNumber !== '') && (
                  <DynamicContactInfo>
                    <ContactInfoSection>
                      {(location !== '') && <small className="bold">{location}</small>}
                      {(locationDetails) && <small className="bold">{locationDetails}</small>}
                    </ContactInfoSection>
                    <ContactInfoSection>
                      {(phoneNumber !== '') && <small><a href={`tel:${phoneNumber}`}>{phoneNumber}</a></small>}
                      {(phoneNumberDetails !== '') && <small>{phoneNumberDetails}</small>}
                    </ContactInfoSection>
                  </DynamicContactInfo>
                )}
              </LocationHoursContainer>
            )}
            {(body) && <DynamicBody>{globalContentParser(fixCmsLinks(body))}</DynamicBody>}
            {(menu?.value)
              && <DynamicMenu>{globalContentParser(fixCmsLinks(menu.value))}</DynamicMenu>}
            {(author) && <p>{author}</p>}
            {(contactName) && <p>{contactName}</p>}
            {(contactEmail) && <p>{contactEmail}</p>}
            {(contactPhone) && <p>{contactPhone}</p>}
          </ContentContainer>
          {(slider || sidebarContent) && (
            <SplitContentContainer>
              {(slider) && (
                <Slider {...cleanSliderData(slider)} parentColorProfile={modal} />
              )}
              {(sidebarContent?.value) && (
                <DynamicBody>{globalContentParser(fixCmsLinks(sidebarContent.value))}</DynamicBody>
              )}
            </SplitContentContainer>
          )}
        </RowSection>
      )}
      {(table && Object?.values(table)?.filter((x) => x)?.length > 0) && (
        <UiTable
          tables={[{ rows: cleanTableData(table) }]}
          parentColorProfile={modal}
        />
      )}
      {(additionalContent?.value)
        && <DynamicBody>{globalContentParser(fixCmsLinks(additionalContent?.value))}</DynamicBody>}
      {(columnContent?.value && columnGallery) && (
        <UiColumn
          relationships={{
            columnComponents: [
              columnGallery,
              {
                id: Math.random(),
                typeName: DrupalComponents.UI_HTML,
                html: { value: columnContent?.value },
              },
            ],
          }}
          contentPosition={VerticalPosition.MIDDLE}
        />
      )}
      {(dropdowns?.relationships) && <CollapsePanelGroup relationships={dropdowns.relationships} />}
    </StyledTemplatedContent>
  );
};

TemplatedContent.propTypes = {
  fields: PropTypes.shape().isRequired,
  isParentModal: PropTypes.bool,
};

TemplatedContent.defaultProps = {
  isParentModal: true,
};
