import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Components, Breakpoints,
} from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.PAGE_HEADER;

export const StyledPageTitle = styled.h2`
  margin: 50px 0 30px;
  font-size: ${({ theme }) => theme.FontSize.LRG12};
`;

export const StyledPageHeader = styled.div`
  ${Breakpoints.mobile(css`
    background-color: ${() => themeUtil(Prop.BACKGROUND, CPNT)};
  `)}
  display: contents;
`;

export const ThirdLevelNav = styled.nav`
  margin-top: 25px;

  ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  li {
    flex: 0;
    margin-bottom: 20px;
  }
  h2 {
    margin-bottom: 0;
  }

  a {
    background-color: ${() => themeUtil(Prop.BACKGROUND, CPNT)};
    color: ${() => themeUtil(Prop.HEADER, CPNT)};
    white-space: nowrap;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;

    &:hover, &:focus {
      transition: background-color 0.25s ease-out, color 0.25s ease-out;
      background-color: ${() => themeUtil(Prop.BACKGROUND, CPNT)};
      color: ${() => themeUtil(Prop.LINK_TXT, CPNT)};
    }

    &:focus {
      outline: 2px solid ${() => themeUtil(Prop.LINK_TXT, CPNT)};
      outline-offset: -5px;
    }

    &.m-active {
      background-color: ${() => themeUtil(Prop.BTN_BG_ACTIVE, CPNT)};
      color: ${() => themeUtil(Prop.BTN_TXT_ACTIVE, CPNT)};
      &:hover {
        color: ${() => themeUtil(Prop.BTN_TXT_ACTIVE, CPNT)};
      }
    }
  }

  ${Breakpoints.mobileTablet(css`
    li {
      margin-right: rem(10) !important;
      &:last-child {
        margin-right: 0 !important;
      }
    }
    a {
      background-color: ${() => themeUtil(Prop.BTN_BG, CPNT)};
      color: ${() => themeUtil(Prop.BTN_TXT, CPNT)};
      &:hover {
        background-color: ${() => themeUtil(Prop.BTN_BG_HOVER, CPNT)};
        color: ${() => themeUtil(Prop.BTN_TXT_HOVER, CPNT)};
      }
      &.m-active {
        background-color: ${() => themeUtil(Prop.BTN_BG_ACTIVE, CPNT)};
        color: ${() => themeUtil(Prop.BTN_TXT_ACTIVE, CPNT)};
      }
    }
  `)}

  // TODO: Figure this out with theme
  ${Breakpoints.mobile(css`
    .alt-colors & {
      a {
        background-color: color(base, btn-bg, alt);
        color: color(base, btn-txt, alt);
        &:hover {
          background-color: color(base, btn-bg-hover, alt);
          color: color(base, btn-txt-hover, alt);
        }
        &.m-active {
          background-color: color(base, btn-bg-active, alt);
          color: color(base, btn-txt-active, alt);
        }
      }
    }
  `)}
`;

export const PageBackButton = styled.div`
  button {
    ${({ theme }) => theme.fonts.PRI_BDY_HVY};
    display: flex;
    text-transform: uppercase;
    flex-direction: row-reverse;
    align-items: flex-end;
    margin-bottom: 20px;
    line-height: 19px;
    font-size: ${({ theme }) => theme.FontSize.REG40};

    .levels-back-icon {
      margin-right: 5px;
      svg {
        fill: ${() => themeUtil(Prop.CONTENT, CPNT)};

      }
    }
  }

  .level-back-icon {
    display: none;
  }
`;
