import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Breakpoints, Components,
} from '@powdr/constants';
import { themeUtil, rgbaTransparencyToHex } from '@powdr/utils';

const CPNT = Components.MODAL;

const notDialogAlertStyles = css`
  -webkit-overflow-scrolling: touch;
  overflow: hidden;

  ${Breakpoints.mobile(css`
    overflow: scroll;
  `)}
`;

const notFullScreen = css`
  border: 10px solid ${({ $colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, $colorProfile)};
  border-radius: 10px;
  margin: 100px 100px 0 100px;
  position: relative;
  width: calc(100% - 200px);
  height: calc(100% - 200px);

  ${Breakpoints.mobile(css`
    width: calc(100% - 100px);
    height: calc(100% - 100px);
    margin: 50px;
  `)}

  ${Breakpoints.portrait(css`
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    margin: 20px;
  `)}

  ${Breakpoints.portraitSmall(css`
    border: 0;
    border-radius: 0;
    width: 100%;
    height: 100%;
    margin: 0;
  `)}
`;

const closeButtonShared = css`
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9;

  ${Breakpoints.mobile(css`
    right: 15px;
    top: 15px;
  `)}

  ${Breakpoints.portrait(css`
    right: 10px;
    top: 10px;
  `)}

  ${Breakpoints.portraitSmall(css`
    right: 12px;
    top: 12px;
  `)}
`;

export const ModalOverlay = styled.div`
  /* background-color: ${($colorProfile) => themeUtil(Prop.ICON, CPNT, $colorProfile) + rgbaTransparencyToHex(0.75)}; */
  background-color: ${() => themeUtil(Prop.ICON, CPNT) + rgbaTransparencyToHex(0.75)};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 198;
`;

export const DialogContent = styled.div`
  position: absolute;
  height: 100%;
  margin: 0 auto;
  width: 100%;
  ${({ isDialogAlert }) => !isDialogAlert && notDialogAlertStyles}
`;

export const DialogAlert = styled.div`
  background: ${({ $colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, $colorProfile)};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const DialogPage = styled.section`
  background: ${({ $colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, $colorProfile)};
  width: 100%;
  height: 100%;
  border-radius: 0;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 199;
  ${({ isFullScreen }) => !isFullScreen && notFullScreen}
`;

export const CloseButton = styled.button`
  ${closeButtonShared}
  background-color: ${({ $colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, $colorProfile)};
  border-radius: 25px;
  display: block;
  padding: 15px;

  svg {
    fill: ${({ $colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, $colorProfile)};
  }

  &:hover {
    background-color: ${({ $colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, $colorProfile)};

    svg {
      fill: ${({ $colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, $colorProfile)};
    }
  }
`;

export const CloseButtonAlt = styled.button`
  align-items: center;
  display: flex;
  ${closeButtonShared}

  .close {
    color: ${({ $colorProfile }) => themeUtil(Prop.CONTENT, CPNT, $colorProfile)};
    ${({ theme }) => theme.fonts.PRI_BDY_HVY};
    font-size: ${({ theme }) => theme.FontSize.REG50};
    padding-right: 10px;
    text-transform: uppercase;
  }

  .button {
    background-color: ${({ $colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, $colorProfile)};

    svg {
      fill: ${({ $colorProfile }) => themeUtil(Prop.CONTENT, CPNT, $colorProfile)};
    }
  }
`;

export const DialogHeader = styled.div``;
export const DialogBody = styled.div`
  width: 100%;
  height: 100%;
`;
export const DialogFooter = styled.div``;
