import styled from 'styled-components';

import {
  Components, ThemeProps as Prop, StaticProfiles,
} from '@powdr/constants';
import { themeUtil, getDorComponentColor } from '@powdr/utils';

const CPNT = Components.DOR;

export const StyledDorPercentWheel = styled.div`
  svg.progress {
    width: 100%;
    margin: 0 auto;

    .meter,
    .value {
      fill: none;
      transform: rotate(-90deg);
      transform-origin: 50%;
    }

    .meter {
      stroke: ${() => themeUtil(Prop.LIGHTER, CPNT, StaticProfiles.SHADES)};
    }

    .value {
      stroke: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.ICON, CPNT)};
      stroke-linecap: round;
    }

    text {
      fill: ${({ isParentNavFeed, colorProfile }) => getDorComponentColor(isParentNavFeed, colorProfile, Prop.CONTENT, CPNT)};
      ${({ theme }) => theme.fonts.PRI_BDY_MED};
      white-space: nowrap;
    }
  }
`;
