import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Breakpoints, Alignment, Components, ComponentGroups, VerticalPosition,
} from '@powdr/constants';
import { themeUtil, componentFontSize } from '@powdr/utils';

const CPNT = Components.FLOATING_IMAGE_SECTION;

export const StyledFloatingImageSection = styled.div`
  display: grid;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};

  ${Breakpoints.mobile(css`
    display: flex;
    ${({ mobileImagePosition }) => (
    (mobileImagePosition)
      ? `flex-direction: ${(mobileImagePosition === VerticalPosition.TOP)
        ? 'column-reverse'
        : 'column'};`
      : '')};
  `)}
`;

export const ContentSection = styled.div`
  ${Breakpoints.desktopOnly(css`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    grid-area: 1/1;
    position: relative;
    z-index: 1;

    ${({ contentAlignment }) => `
      padding-${contentAlignment}: 100px;
      justify-content: ${contentAlignment};`};
  `)}

  ${Breakpoints.mobile(css`
    padding: 45px;
  `)}
`;

export const ContentWrapper = styled.div`
  ${Breakpoints.desktopOnly(css`
    width: ${({ width }) => `${width}%`};
  `)}
`;

export const Content = styled.div`
  p, li, ul {
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${({ colorProfile }) => themeUtil(Prop.HEADER, CPNT, colorProfile)};
    a { font-size: inherit; }
  }

  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
  }

  a {
    transition: color .25s ease-out, background-color .25s ease-out;
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};

    &:hover {
      color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
    }
  }

  .btn {
    ${({ theme }) => theme.fonts.PRI_BDY_HVY};
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'button')}
    border: none;
    cursor: pointer;
    display: inline-block;
    position: relative;
    text-align: center;
    width: auto;
    height: auto;
    padding: 16px 20px;
    text-transform: none;
    margin: 0;
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};

    &:hover {
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    }
  }

  h1 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h1')}
    margin-bottom: 40px;
  }

  h2 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h2')}
    margin-bottom: 20px;
  }

  h3 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h3')}
    margin-bottom: 15px;
  }

  h4 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h4')}
    margin-bottom: 10px;
  }

  h5 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h5')}
    margin-bottom: 10px;
  }

  h6 {
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'h6')}
    margin-bottom: 5px;
  }

  ul, ol {
    margin-bottom: 25px;
  }

  ul {
    li {
      list-style: disc outside none;
      margin-left: 15px;
      margin-bottom: 5px;
      line-height: 1.25;
    }
  }

  ol {
    li {
      list-style: decimal;
      margin-left: 15px;
      margin-bottom: 5px;
    }
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  li, p, table {
    ${({ theme }) => theme.fonts.PRI_BDY_MED};
    ${() => componentFontSize(ComponentGroups.WYSIWIG, 'content')}

    ${Breakpoints.large(css`
      ${() => componentFontSize(ComponentGroups.WYSIWIG, 'content-large-breakpoint')}
    `)}
  }

  p {
    line-height: 1.25;
    margin-bottom: 15px
  }

  table {
    width: 100%;
    line-height: 25px;
    margin-bottom: 15px;

    td {
      font-size: 16px;
    }
  }
`;

export const ImageWrapper = styled.div`
  ${Breakpoints.desktopOnly(css`
    grid-area: 1/1;
  `)}
`;

export const FloatingImageContainer = styled.div`
  ${Breakpoints.desktopOnly(css`
    width: 100%;
  `)}
`;

export const FloatingImage = styled.div`
  height: ${({ height }) => `${height}px`};
`;

export const Image = styled(GatsbyImage)`
  ${Breakpoints.desktopOnly(css`
    height: ${({ height }) => `${height}px`};
    width: 100%;
  `)}

  ${Breakpoints.mobile(css`
    display: block;
    margin: 0;
  `)}

  // fix for FF and Safari image bug
  &.gatsby-image-wrapper [data-main-image] {
    width: auto !important;
  }
`;
