import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon } from '@powdr/components/icon';
import { Link } from '@powdr/components/link';
import { BlockTypes, ThemeProps as Prop } from '@powdr/constants';
import { ContentBlockType } from '@powdr/model';
import { themeUtil, rgbaTransparencyToHex } from '@powdr/utils';

const CPNT = BlockTypes.COVER;

// styled components
const ContentBlockCover = styled(Link)`
  .cta-w-cover {
    position: relative;
    overflow: hidden;
  }

  span.animate:not(.overlay) {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile) + rgbaTransparencyToHex(0.7)};
    transition: all 0.35s ease-in-out;
    position: absolute;
    opacity: 1;
    display: block;
    width: 35px;
    height: 35px;
    top: 50%;
    left:0;
    right:0;
    bottom:0;
    border-radius: 50%;
    margin: 0 auto;
    z-index: 5;
  }

  &:hover .animate, &:focus .animate  {
    transform: scale(45, 45);
    opacity: 1;
  }

  .cb-title {
    color: ${({ colorProfile }) => themeUtil(Prop.HEADER, CPNT, colorProfile)};
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
  }

  .cta-w-cover-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    overflow: hidden;
    width: 100%; height: auto;
    text-align: center;
    padding: 0;
    margin: 0;
    z-index: 10;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
    width: 50px;
    height: 50px;
    border-radius: 50%;

    .button-icon  {
      display: inline-block;
      position: relative;

      svg {
        fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
      }
    }
  }

  &:hover:not(.overlay), &:focus:not(.overlay) {
    .button  {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    }

    .button-icon  {
      svg {
        fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      }
    }
  }
`;

const Image = styled.img``;

export const Cover = ({
  title, isOverlayEnabled, image, colorProfile, blockType, isStaticImageLink, ariaLabel,
  buttonEnabled, buttonLink, buttonTarget, buttonClick,
}) => (
  <ContentBlockCover
    href={buttonLink}
    target={buttonTarget}
    onClick={() => buttonClick()}
    aria-label={ariaLabel}
    role="button"
    colorProfile={colorProfile}
    className={`content-block-${blockType} `}
  >
    <figure className={classNames('cta-w-cover', { overlay: isOverlayEnabled })}>
      <span className="animate" />
      <div className="cta-w-cover-inner">
        <h3 className="cb-title">{title}</h3>
        {(buttonEnabled) && (
          <div className="button" aria-label={ariaLabel}>
            <Icon className="button-icon" name="ui-plus-thick" width="13" height="13" />
          </div>
        )}
      </div>
      {(image && !isStaticImageLink) && <GatsbyImage image={image.src} alt={image?.altText} />}
      {(image && isStaticImageLink) && <Image src={image.src} alt={image?.altText} />}
    </figure>
  </ContentBlockCover>
);

Cover.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
