import { ThemeProps as Prop, ColorProfiles, Components } from '@powdr/constants';

export const defaultTheme = {
  [ColorProfiles.BASE]: {
    [Prop.BACKGROUND]: '#ffffff',
    [Prop.HEADER]: '#3b3937',
    [Prop.CONTENT]: '#606060',
    [Prop.LINK_TXT]: '#b7b186',
    [Prop.LINK_TXT_HOVER]: '#006a51',
    [Prop.BTN_BG]: '#b7b186',
    [Prop.BTN_TXT]: '#006a51',
    [Prop.BTN_BG_HOVER]: '#006a51',
    [Prop.BTN_TXT_HOVER]: '#b7b186',
    [Prop.BTN_BG_ACTIVE]: '#b7b186',
    [Prop.BTN_TXT_ACTIVE]: '#006a51',
    [Prop.BTN_BG_INACTIVE]: '#dedede',
    [Prop.BTN_TXT_INACTIVE]: '#006a51',
    [Prop.ELEMENT]: '#b7b186',
    [Prop.ELEMENT_TXT]: '#006a51',
    [Prop.BORDER]: '#dedede',
    [Prop.ICON]: '#b7b186',
    [Prop.ICON_HOVER]: '#006a51',
    [Prop.ICON_2]: '#b7b186',
    [Prop.ICON_HOVER_2]: '#b7b186',
    [Prop.HEADER_BG]: '#000000',
    [Prop.HEADER_TXT]: '#ffffff',
    [Prop.HEADER_ICON]: '#606060',
    [Prop.HEADER_BG_2]: '#b7b186',
    [Prop.HEADER_TXT_2]: '#ffffff',
    [Prop.HEADER_ICON_2]: '#ffffff',
    [Prop.ODD_EVEN_OFFSET]: '#e5eded',
  },
  [ColorProfiles.PRIMARY]: {
    [Prop.BACKGROUND]: '#006a51',
    [Prop.HEADER]: '#f0f0f0',
    [Prop.CONTENT]: '#f0f0f0',
    [Prop.LINK_TXT]: '#b7b186',
    [Prop.LINK_TXT_HOVER]: '#f0f0f0',
    [Prop.BTN_BG]: '#b7b186',
    [Prop.BTN_TXT]: '#006a51',
    [Prop.BTN_BG_HOVER]: '#f0f0f0',
    [Prop.BTN_TXT_HOVER]: '#006a51',
    [Prop.BTN_BG_ACTIVE]: '#f0f0f0',
    [Prop.BTN_TXT_ACTIVE]: '#006a51',
    [Prop.BTN_BG_INACTIVE]: '#434e35',
    [Prop.BTN_TXT_INACTIVE]: '#b7b186',
    [Prop.ELEMENT]: '#b7b186',
    [Prop.ELEMENT_TXT]: '#006a51',
    [Prop.BORDER]: '#434e35',
    [Prop.ICON]: '#b7b186',
    [Prop.ICON_HOVER]: '#f0f0f0',
  },
  [ColorProfiles.SECONDARY]: {
    [Prop.BACKGROUND]: '#b7b186',
    [Prop.HEADER]: '#006a51',
    [Prop.CONTENT]: '#006a51',
    [Prop.LINK_TXT]: '#ffffff',
    [Prop.LINK_TXT_HOVER]: '#006a51',
    [Prop.BTN_BG]: '#1f2b0e',
    [Prop.BTN_TXT]: '#f0f0f0',
    [Prop.BTN_BG_HOVER]: '#f0f0f0',
    [Prop.BTN_TXT_HOVER]: '#b7b186',
    [Prop.BTN_BG_ACTIVE]: '#f0f0f0',
    [Prop.BTN_TXT_ACTIVE]: '#b7b186',
    [Prop.BTN_BG_INACTIVE]: '#908e67',
    [Prop.BTN_TXT_INACTIVE]: '#006a51',
    [Prop.ELEMENT]: '#006a51',
    [Prop.ELEMENT_TXT]: '#f0f0f0',
    [Prop.BORDER]: '#908e67',
    [Prop.ICON]: '#f0f0f0',
    [Prop.ICON_HOVER]: '#006a51',
  },
  [ColorProfiles.TERTIARY]: {
    [Prop.BACKGROUND]: '#f0f0f0',
    [Prop.HEADER]: '#3b3937',
    [Prop.CONTENT]: '#606060',
    [Prop.LINK_TXT]: '#b7b186',
    [Prop.LINK_TXT_HOVER]: '#006a51',
    [Prop.BTN_BG]: '#b7b186',
    [Prop.BTN_TXT]: '#006a51',
    [Prop.BTN_BG_HOVER]: '#006a51',
    [Prop.BTN_TXT_HOVER]: '#b7b186',
    [Prop.BTN_BG_ACTIVE]: '#006a51',
    [Prop.BTN_TXT_ACTIVE]: '#b7b186',
    [Prop.BTN_BG_INACTIVE]: '#d1d1d1',
    [Prop.BTN_TXT_INACTIVE]: '#006a51',
    [Prop.ELEMENT]: '#b7b186',
    [Prop.ELEMENT_TXT]: '#006a51',
    [Prop.BORDER]: '#d1d1d1',
    [Prop.ICON]: '#b7b186',
    [Prop.ICON_HOVER]: '#006a51',
  },
  [ColorProfiles.QUATERNARY]: {
    [Prop.BACKGROUND]: '#333333',
    [Prop.HEADER]: '#f0f0f0',
    [Prop.CONTENT]: '#f0f0f0',
    [Prop.LINK_TXT]: '#f0f0f0',
    [Prop.LINK_TXT_HOVER]: '#b7b186',
    [Prop.BTN_BG]: '#b7b186',
    [Prop.BTN_TXT]: '#006a51',
    [Prop.BTN_BG_HOVER]: '#f0f0f0',
    [Prop.BTN_TXT_HOVER]: '#006a51',
    [Prop.BTN_BG_ACTIVE]: '#f0f0f0',
    [Prop.BTN_TXT_ACTIVE]: '#006a51',
    [Prop.BTN_BG_INACTIVE]: '#555348',
    [Prop.BTN_TXT_INACTIVE]: '#b7b186',
    [Prop.ELEMENT]: '#b7b186',
    [Prop.ELEMENT_TXT]: '#006a51',
    [Prop.BORDER]: '#626262',
    [Prop.ICON]: '#b7b186',
    [Prop.ICON_HOVER]: '#f0f0f0',
  },
  [Components.HEADER]: {
    [Prop.LOGO_BG]: '#006a51',
    [Prop.NAV_BG]: '#006a51',
    [Prop.NAV_ITEM_TXT_TRANSLUCENT]: '#f0f0f0',
    [Prop.NAV_ITEM_TXT_SOLID]: '#f0f0f0',
    [Prop.NAV_ITEM_TXT_ACTIVE]: '#006a51',
    [Prop.NAV_ITEM_BG_ACTIVE]: '#b7b186',
    [Prop.NAV_ITEM_ACTIVE_ACCENT]: '#b7b186',
    [Prop.WW_ICON_COLOR]: '#FFD200',
  },
  [Components.MOBILE_NAV]: {
    [Prop.MENU_BTN_BG]: '#FFFFFF',
    [Prop.MENU_BTN_ICON]: '#b7b186',
    [Prop.LOGO_BG]: '#006a51',
    [Prop.CLOSE_BTN_BG]: '#b7b186',
    [Prop.CLOSE_BTN_ICON]: '#006a51',
    [Prop.MAIN_NAV_BG]: '#F0F0F0',
    [Prop.MAIN_NAV_TXT]: '#3b3937',
    [Prop.MAIN_NAV_BORDER]: '#3b3937',
    [Prop.WW_ICON_COLOR]: '#FFD200',
    [Prop.DRAWER_BTN_BG]: '#006a51',
    [Prop.DRAWER_BTN_TXT]: '#b7b186',
    [Prop.PROMO_BTN_BG]: '#006a51',
    [Prop.PROMO_BTN_TXT]: '#b7b186',
    [Prop.SUB_NAV_BG]: '#b7b186',
    [Prop.SUB_NAV_TXT]: '#006a51',
    [Prop.SUB_NAV_BORDER]: '#FFFFFF',
  },
  [Components.PROMOTIONS_FEED]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.LINK_TXT]: '#006a51',
  },
  [Components.STICKY_NAV]: {
    [Prop.BTN_TXT]: '#006a51',
    [Prop.BTN_TXT_HOVER]: '#f0f0f0',
    [Prop.BTN_BG_ACTIVE]: '#f0f0f0',
    [Prop.BTN_TXT_ACTIVE]: '#006a51',
  },
};
