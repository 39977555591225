import styled, { css } from 'styled-components';

import { Icon, Link } from '@powdr/components';
import {
  ThemeProps as Prop, Components, Breakpoints, HeaderStyles, NavigationStyles,
} from '@powdr/constants';
import { rgbaTransparencyToHex, componentColor } from '@powdr/utils';

const CPNT_DESKTOP = Components.HEADER;
const CPNT_MOBILE = Components.MOBILE_NAV;

export const StyledMainNav = styled.div`
  pointer-events: all;
  height: ${({ $splitHeight }) => (($splitHeight) ? '50%' : '100%')};
  width: 100%;
  white-space: nowrap;

  ${Breakpoints.desktopOnly(css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    ${({ $isRightAlignedNav }) => (($isRightAlignedNav) ? 'justify-content: flex-end;' : '')}
  `)}

  ${Breakpoints.mobile(css`
    height: auto;
  `)}

  ${({ background, opacity, isMobile }) => (
    (background === HeaderStyles.TRANSLUCENT)
      ? `background-color: ${`${componentColor(Prop.NAV_BG, CPNT_DESKTOP, Prop.BACKGROUND)}${rgbaTransparencyToHex((opacity / 100))};`};`
      : `${(!isMobile) ? `background-color: ${componentColor(Prop.NAV_BG, CPNT_DESKTOP, Prop.BACKGROUND)};` : ''}`
  )}
`;

export const MainNavigationList = styled.ul`
  ${Breakpoints.desktopOnly(css`
    position: ${({ navigationStyle }) => ((navigationStyle === NavigationStyles.ITEM_WIDTH) ? 'relative' : 'static')};
    overflow: inherit;
    ${({ isRightAlignedNav }) => ((isRightAlignedNav) ? `
      display: grid;
      grid-auto-columns: 100%;
      grid-row: 1;
      justify-content: flex-end;
      grid-auto-columns: minmax(0, 1fr);
      grid-auto-flow: column;
    ` : `
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
    `)};
  `)}

  ${Breakpoints.mobile(css`
    height: 100%;
    overflow: hidden;
  `)}
`;

export const NavigationItem = styled.li`
  height: 100%;

  ${Breakpoints.desktopOnly(css`
    ${({ isRightAlignedNav, navigationStyle }) => ((isRightAlignedNav) ? `
      position: static;
    ` : `
      overflow: inherit;
      flex-grow: 1;
      flex-basis: 0;
      position: ${((navigationStyle === NavigationStyles.ITEM_WIDTH) ? 'relative' : 'static')};
    `)};

  `)}

  ${Breakpoints.mobile(css`
    display: block;
    margin: 0px;
    padding: 0px;
    height: fit-content;

    &:first-child .nav-item-content {
      border-top: 1px solid ${() => componentColor(Prop.MAIN_NAV_BORDER, CPNT_MOBILE, Prop.BORDER)};
    }
  `)}

  &:focus {
    .nav-item-content .item-text {
      outline: 2px solid ${() => componentColor(Prop.NAV_ITEM_TXT_ACTIVE, CPNT_DESKTOP, Prop.BTN_TXT_ACTIVE)};
      outline-offset: 0.2em;
    }

    .nav-item-content {
      outline: 2px solid ${() => componentColor(Prop.NAV_ITEM_TXT_ACTIVE, CPNT_DESKTOP, Prop.BTN_TXT_ACTIVE)};
      outline-offset: -15px;
    }

    .woodward-nav-item-content {
      outline: 2px solid ${() => componentColor(Prop.WW_ICON_COLOR, CPNT_DESKTOP, Prop.CONTENT)};
      outline-offset: -15px;
    }
  }
`;

export const NavigationLevel1OnlyLink = styled(Link)`
  &:focus {
    .nav-item-content .item-text {
      outline: 2px solid ${() => componentColor(Prop.NAV_ITEM_TXT_ACTIVE, CPNT_DESKTOP, Prop.BTN_TXT_ACTIVE)};
      outline-offset: 0.2em;
    }
  }
`;

export const NavigationItemInnerContentWrapper = styled.div`
  --hoverBg: ${({ isMatchScrollState }) => ((isMatchScrollState) ? '' : componentColor(Prop.NAV_ITEM_BG_ACTIVE, CPNT_DESKTOP, Prop.BACKGROUND))};
  --hoverColor: ${() => componentColor(Prop.NAV_ITEM_TXT_ACTIVE, CPNT_DESKTOP, Prop.CONTENT)};
  --hoverWWSvg: ${() => componentColor(Prop.WW_ICON_COLOR, CPNT_DESKTOP, Prop.CONTENT)};
  height: 100%;
  width: 100%;

  ${Breakpoints.desktopOnly(css`
    display: block;
    position: relative;
    z-index: 99;

    /* TODO: figure out why hover state causes a local dev specific warning about hooks */
    /* Handle navigation item hover states */
    ${({ isOpen }) => ((isOpen) ? `
      div.bar { height: 4px; }
      div.arrow { bottom: -16px; opacity: 1; }

      .nav-item-content {
        background-color: var(--hoverBg);
        color: var(--hoverColor);
      }

      .woodward-nav-item-content {
        background-color: #FFFFFF;

        svg {
          fill: var(--hoverWWSvg);
        }
      }
    ` : '')}
  `)}
`;

export const NavItemContent = styled.div`
  --WWIconColor: ${() => componentColor(Prop.WW_ICON_COLOR, CPNT_DESKTOP, Prop.CONTENT)};
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.REG30};
  text-align: center;
  cursor: default;
  display: block;
  line-height: 1em;
  overflow: hidden;

  ${({ isRightAlignedNav }) => ((isRightAlignedNav) ? `
    padding: 0 20px;
  ` : `
    padding: 0 5px;
  `)};

  ${({ isNavActiveUnderline }) => ((isNavActiveUnderline) ? `
    .underline-element {
      position: absolute;
      color: black;
      background-color: black;
      height: 2px;
      width: calc(100% - 40px);
      top: 60%;
      transform: scaleX(0);
      transition: transform .25s ease-in-out;
    }

    &:hover {
      .underline-element {
        display: block;
        transform: scaleX(1);
        transition: transform .25s ease-in-out;
      }
    }
    ` : '')};
  position: relative;
  width: calc(100% + 2px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: ${({ theme }) => ((theme?.HeaderTransform) ? theme.HeaderTransform : 'uppercase')};
  transition: color 0.25s ease-out, fill 0.25s ease-out;
  color: ${({ background }) => ((background === HeaderStyles.TRANSLUCENT)
    ? componentColor(Prop.NAV_ITEM_TXT_TRANSLUCENT, CPNT_DESKTOP, Prop.CONTENT)
    : componentColor(Prop.NAV_ITEM_TXT_SOLID, CPNT_DESKTOP, Prop.CONTENT))};

  svg {
    fill: ${({ background }) => ((background === HeaderStyles.TRANSLUCENT)
    ? componentColor(Prop.NAV_ITEM_TXT_TRANSLUCENT, CPNT_DESKTOP, Prop.CONTENT)
    : componentColor(Prop.NAV_ITEM_TXT_SOLID, CPNT_DESKTOP, Prop.CONTENT))};
  }

  &.level-1-only:hover {
    cursor: pointer;
    transition: 0.25s 0.2s;
    background-color: ${({ isMatchScrollState, background }) => ((isMatchScrollState && background === HeaderStyles.TRANSLUCENT) ? '' : componentColor(Prop.NAV_ITEM_BG_ACTIVE, CPNT_DESKTOP, Prop.BACKGROUND))};
    color: ${() => componentColor(Prop.NAV_ITEM_TXT_ACTIVE, CPNT_DESKTOP, Prop.CONTENT)};
  }

  ${({ background }) => ((background === HeaderStyles.SOLID) ? `
    &.woodward-nav-item-content {
      svg {
        fill: var(--WWIconColor);
      }
    }
  ` : '')}

  ${Breakpoints.mobile(css`
    ${({ theme }) => theme.fonts.PRI_BDY_HVY};
    font-size: ${({ theme }) => theme.FontSize.REG30};
    color: ${() => componentColor(Prop.MAIN_NAV_TXT, CPNT_MOBILE, Prop.LINK_TXT)};
    background-color: ${() => componentColor(Prop.MAIN_NAV_BG, CPNT_MOBILE, Prop.BACKGROUND)};
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    padding: 20px 30px;
    height: 55px;
    border-bottom: 1px solid ${() => componentColor(Prop.MAIN_NAV_BORDER, CPNT_MOBILE, Prop.BORDER)};

    .active-indicator svg {
      fill: ${() => componentColor(Prop.MAIN_NAV_TXT, CPNT_MOBILE, Prop.LINK_TXT)};
    }

    &.woodward-nav-item-content {
      svg {
        fill: ${() => componentColor(Prop.WW_ICON_COLOR, CPNT_MOBILE, Prop.CONTENT)};
      }
    }
  `)}

  ${Breakpoints.large(css`
    font-size: ${({ theme }) => theme.FontSize.REG40};
  `)}

  ${Breakpoints.larger(css`
    font-size: ${({ theme }) => theme.FontSize.REG50};
  `)}

`;

export const HoverStateElement = styled.div`
  &.bar {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: calc(100% - 1px);
    bottom: 0px;
    left: 1px;
    transition: height 0.25s linear;
    z-index: 3;
    background-color: ${({ isMatchScrollState }) => ((isMatchScrollState) ? '' : componentColor(Prop.NAV_ITEM_ACTIVE_ACCENT, CPNT_DESKTOP, Prop.BACKGROUND))};
  }

  &.arrow {
    content: " ";
    transition: bottom 0.25s linear, opacity 0.1s 0.1s linear;
    border: solid transparent;
    border-width: 8px;
    position: absolute;
    opacity: 0;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -8px;
    border-top-color: ${({ isMatchScrollState }) => ((isMatchScrollState) ? '' : componentColor(Prop.NAV_ITEM_ACTIVE_ACCENT, CPNT_DESKTOP, Prop.BACKGROUND))};
  }

  .woodward-nav-item & {
    &.bar, &.arrow {
      display: none;
    }
  }
`;

export const WoodwardNavIcon = styled(Icon)`
  width: 90%;
  max-width: 120px;
`;
