import styled from 'styled-components';

import {
  ThemeProps as Prop, Components,
} from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.CONTENT_BLOCK_CAROUSEL;

export const StyledContentBlockCarousel = styled.div``;

export const CarouselIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 0;

  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
  }
`;
