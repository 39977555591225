/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';
import React, {
  useState, useCallback, useRef, useMemo, useContext,
} from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
  Navigation, A11y, Parallax, Controller, EffectFade,
} from 'swiper/modules';

import { CarouselTransitionEffects, KeydownEventCodes, NavigationDirection } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { doubleDigitizer, getImageAltText } from '@powdr/utils';

import {
  StyledBannerCarousel, StyledSwiper, StyledSwiperSlide,
  SlideWrapper, BackgroundImage, SlideContent, SlideContentInnerWrapper,
  StaticCarouselComponents, ContentCarouselWrapper, ControlArrowWrapper, ControlArrow,
  Title,
  ControlArrowIcon,
  SlideIndex,
} from './styles';

export const BannerCarousel = ({
  className,
  title,
  componentHeight,
  isShowSlideIndexes,
  relationships,
}) => {
  const { isMobile } = useContext(AppContext);
  const carouselItems = relationships?.bannerSlides;
  const animationSpeed = 600; // ms
  const [keyboardControlEnabled, setKeyboardControlEnabled] = useState(false);
  const contentCarousel = useRef();
  const backgroundCarousel = useRef();

  const navigate = useCallback(
    (direction) => (
      (direction === NavigationDirection.PREVIOUS)
        ? contentCarousel.current?.slidePrev()
        : contentCarousel.current?.slideNext()),
    [contentCarousel],
  );

  const keyboardControl = useCallback((e) => {
    if (!keyboardControlEnabled || !contentCarousel?.current) return;

    if (e.keyCode === KeydownEventCodes.LEFT) {
      navigate(NavigationDirection.PREVIOUS);
    }

    if (e.keyCode === KeydownEventCodes.RIGHT) {
      navigate(NavigationDirection.NEXT);
    }
  }, [keyboardControlEnabled, navigate]);

  const ControlArrowHandler = ({
    direction,
    iconName,
  }) => useMemo(() => (
    <ControlArrowWrapper
      className={`control-${direction}`}
      direction={direction}
      onClick={(e) => { e.stopPropagation(); navigate(direction); }}
      onMouseDown={(e) => { e.preventDefault(); }}
    >
      <ControlArrow
        className="control-arrow"
        direction={direction}
      >
        <ControlArrowIcon
          className="control-arrow-icon"
          name={iconName}
        />
      </ControlArrow>
    </ControlArrowWrapper>
  ), [direction, iconName]);

  return (
    <StyledBannerCarousel
      tabIndex={0}
      onFocus={() => setKeyboardControlEnabled(true)}
      onBlur={() => setKeyboardControlEnabled(false)}
      onKeyDown={(e) => keyboardControl(e)}
      aria-label="Use left and right arrow keys to navigate the carousel"
      $componentHeight={componentHeight}
    >
      <StyledSwiper
        onBeforeInit={(swiper) => { backgroundCarousel.current = swiper; }}
        modules={[Navigation, A11y, Controller, EffectFade]}
        speed={animationSpeed}
        slidesPerView={1}
        slidesPerGroup={1}
        controller={{
          control: contentCarousel.current,
        }}
        // effect={CarouselTransitionEffects.FADE}
      >
        {carouselItems.map((slide) => {
          const { gatsbyImage: image } = slide.relationships?.primaryImage?.relationships?.image || {};
          const { gatsbyImage: mobileImage } = slide.relationships?.mobileImage?.relationships?.image || {};

          return (
            <StyledSwiperSlide key={slide.key}>
              <SlideWrapper>
                <BackgroundImage
                  image={(isMobile && mobileImage)
                    ? mobileImage
                    : image}
                  alt={getImageAltText(
                    (isMobile && mobileImage)
                      ? mobileImage
                      : image,
                  )}
                />
              </SlideWrapper>
            </StyledSwiperSlide>
          );
        })}
      </StyledSwiper>
      <StaticCarouselComponents $height={componentHeight / 2}>
        <Title>{title}</Title>
        <ContentCarouselWrapper>
          <ControlArrowHandler
            direction={NavigationDirection.PREVIOUS}
            iconName="ui-back"
            iconSize={25}
          />
          <StyledSwiper
            modules={[Navigation, A11y, Parallax, Controller]}
            onBeforeInit={(swiper) => { contentCarousel.current = swiper; }}
            speed={animationSpeed}
            slidesPerView={1}
            slidesPerGroup={1}
            parallax
            navigation={{
              nextEl: `.control-${NavigationDirection.NEXT}`,
              prevEl: `.control-${NavigationDirection.PREVIOUS}`,
            }}
            controller={{
              control: backgroundCarousel.current,
            }}
          >
            {carouselItems.map((slide, idx) => (
              <StyledSwiperSlide>
                <SlideContent
                  data-swiper-parallax-duration={animationSpeed}
                  data-swiper-parallax-opacity="0"
                >
                  {(isShowSlideIndexes) && <SlideIndex>{`${doubleDigitizer(idx + 1)} / `}</SlideIndex>}
                  {ReactHtmlParser(slide.formattedContent.value)}
                </SlideContent>
              </StyledSwiperSlide>

            ))}
          </StyledSwiper>
          <ControlArrowHandler
            direction={NavigationDirection.NEXT}
            iconName="ui-back"
            iconSize={25}
          />
        </ContentCarouselWrapper>
      </StaticCarouselComponents>
    </StyledBannerCarousel>
  );
};

BannerCarousel.propTypes = {
  className: PropTypes.string,
  componentHeight: PropTypes.number, // px
  title: PropTypes.string,
  isShowSlideIndexes: PropTypes.bool,
  relationships: PropTypes.shape({}).isRequired,
};

BannerCarousel.defaultProps = {
  className: '',
  componentHeight: 300, // px
  title: '',
  isShowSlideIndexes: false,
};
