import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Components, Breakpoints,
} from '@powdr/constants';
import { themeUtil, colorShade } from '@powdr/utils';

const CPNT = Components.DOR_LIFTS_TRAILS;

export const StyledDorLiftTrailsDrawer = styled.table`
  display: flex;
  position: relative;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};

  ${Breakpoints.mobile(css`
    display: block;
  `)}

  .all-products {
    min-height: 1000px;
  }

  .row h4 {
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
    font-size: ${({ theme }) => theme.FontSize.MED10};
    padding-left: 5px;
    text-transform: uppercase;
  }

  button.accordion-btn {
    display: flex;
    align-items: center;
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 24px;
    text-transform: uppercase;
    padding: 5px 0 5px;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-top-color: ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};
    border-bottom-color: ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};

    &.collapsed {
      border-bottom: 0;
    }

    .icon-c svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
    }
  }

  header {
    margin-bottom: 25px;
    border-bottom: 1px solid;
    border-bottom-color: ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};

    h3 {
      font-size: ${({ theme }) => theme.FontSize.LRG20};
    }
  }


  icon .icon-c {
    margin: 0 5px 0 0;
  }

  .ui-drawer .drawer {
    padding: 0;
  }

  .drawr-scroll-wrapper {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    padding: 15px;
  }

  .is-fixed {
    position: fixed;
  }

  .ui-drawer {
    .drawer {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
    }
  }

  .ui-drawer {
    .drawer {
      button.fixed-open {
        position: absolute;
        right: 0;
        top: 50px;
        width: 130px;

        &.an-open {
          right: -130px;
        }
      }
    }
  }

  .disabled {
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  }

  .accordion-panel {
    padding-left: 5px;
    padding-right: 5px;

    .c-panel {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 25px;

      &.no-label {
        margin-top: 0;
        padding-top: 15px;
        border-top: 1px solid;
        border-top-color: ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};

      }
    }
  }
`;

export const StyledDorTable = styled.table`
  display: table;
  table-layout: fixed;
  border: none;
  border-collapse: collapse;
  border-collapse: separate;
  margin-bottom: 1px;
  background-color: transparent;
  width: 100%;

  tr {

    &.expanded-row {
      td {
        padding: 10px 0px;
      }
    }

    td {
      color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
      font-size: ${({ theme }) => theme.FontSize.REG30};

      text-align: center;
      margin: 0;
      padding: 8px 12px;

      ${Breakpoints.mobile(css`
        font-size: ${({ theme }) => theme.FontSize.REG10};
        padding: 6px 6px;
        line-height: 15px;
      `)}

      .expanded-td {
       text-align: left;
       font-weight: normal;
       position: relative;
       display: flex;
       gap: 15px;
       padding: 10px 0px;
       isolation: isolate;

       &.col-2 {
        .content,
        .media {
          width: 50%;
        }
       }

       .expand-close-btn {
        align-self: self-start;
        margin-right: 15px;
       }

       .content {
        width: 100%;

        h3 {
          margin-bottom: 10px;
          color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
        }
       }

       .media {
        display: flex;
        width: 100%;
        position: relative;

        img {
          aspect-ratio: 16/9;
          overflow: hidden;
          object-fit: cover;
        }
       }

       .btn {
          font-size: 12px;
          padding: 10px 12px;
          color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
          background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
        }

        .btn:active {
          color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_ACTIVE, CPNT, colorProfile)};
          background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_ACTIVE, CPNT, colorProfile)};
        }

        .btn:hover {
          color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
          background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
        }

       p {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 5px;
       }
      }
      .expand-input-checkbox {
        position: relative;
        .icon {
          cursor: pointer;
        }

        input {
          width: 1px;
          height: 1px;
          position: absolute;
          top: -100%;
          left: -100%;
          opacity: 0;
        }

      }

      &.groomed,
      &.features {
        svg,
        svg path {
          fill:${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
        }
      }
    }
  }

  tr {
    td.features {
      icon {
        display: inline-block;
        margin-right: 5px;
      }
    }

    td.details {
      padding: 10px;

      icon {
        margin: 0;
      }

      button {
        position: relative;
        display: inline-block;
      }
    }

    td.no-trails {
      text-align: left;
    }

    td.description {
      min-width: 30%;
      line-height: 16px;
    }

    td.notes {
      p {
        text-align: left;
      }
    }

    td.notes,
    td.description {
      p {
        font-size: 12px;
        text-align: left;
      }

      span.default {
        display: inline-block;

        ${Breakpoints.mobile(css`
          display: none;
        `)}
      }

      button.more-info-btn {
        display: none;
        position: relative;
        width: 25px;
        height: 25px;

        ${Breakpoints.mobile(css`
          display: inline-block;
        `)}
      }
    }
  }

  thead {
    display: table-header-group;
    vertical-align: middle;

    th {
      font-weight: bold;
      text-transform: uppercase;
      padding: 8px 12px;
      white-space: nowrap;
      width: 100%;
      text-align: center;
      position: sticky;
      top: ${(props) => `${props.top}px`};
      background-color: white;
      z-index: 1;

      ${Breakpoints.mobile(css`
        /* font-size: $font-size-reg-5; */
        font-size: ${({ theme }) => theme.FontSize.REG5};
        padding: 6px 6px;
        line-height: rem(13);
      `)}
    }

    th:first-child {
      text-align: left;
    }

    th:last-child {
      text-align: right;
    }
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;

    td:first-child {
      text-align: left;
      font-weight: 600;
    }

    td:not(.no-trails):last-child {
      text-align: right;
    }
  }

  &.table-strip {
    tbody {
      tr:nth-child(odd) {
        background-color: ${({ colorProfile }) => themeUtil(Prop.ODD_EVEN_OFFSET, CPNT, colorProfile)};
      }
    }
  }

  &.conditions.table-strip {
    tr:nth-child(odd) {
      background-color: ${({ colorProfile }) => colorShade(themeUtil(`${Prop.HEADER_BG_2}`, CPNT, colorProfile), -0.1)};
    }
    tr:nth-child(even) {
      background-color: ${({ colorProfile }) => themeUtil(`${Prop.HEADER_BG_2}`, CPNT, colorProfile)};
    }
  }

  &.conditions td {
    color: ${({ colorProfile }) => themeUtil(Prop.HEADER_TXT_2, CPNT, colorProfile)};
  }
`;

export const StyledLiftsDetails = styled.ul`
  background-color: ${({ colorProfile }) => themeUtil(Prop.HEADER_BG_2, CPNT, colorProfile)};
  color: ${({ colorProfile }) => themeUtil(Prop.HEADER_TXT_2, CPNT, colorProfile)};
  display: flex;
  padding: 10px;
  width: 100%;

  li.item {
    width: 33.333333%;
    margin-right: 10px;
    color: ${({ colorProfile }) => themeUtil(Prop.HEADER_TXT_2, CPNT, colorProfile)};
    strong {
      display: block;
    }
  }
`;

export const StyledLegendTrailTypeRating = styled.div`

  .ui-accordion-panel {
    z-index: 110;
  }

  .col {
    width: auto;

    ${Breakpoints.mobile(css`
      width: 100%;
    `)}

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      background-color: transparent;
      margin-bottom: 20px;

      h4 {
        display: inline-block;
        margin-bottom: 0;
        margin-right: 5px;
        text-transform: uppercase;
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      justify-content: start;
      margin-bottom: 15px;

      span.icon-wrap {
        margin: 5px;
        max-width: 20%;
        text-align: center;
      }

      p {
        margin-top: 5px;
        margin-bottom: 0;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

`;

export const StyledDorSector = styled.div`

  .ui-accordion-panel {
    padding-bottom: 15px;

    &:after {
      content: "";
      background-color: ${({ colorProfile }) => themeUtil(Prop.HEADER_BG_2, CPNT, colorProfile)};
      display: block;
      height: 10px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .panel-label {
      min-width: 30%;
    }

    .panel-body {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
    }

    .panel-render {
      display: flex;
      align-items: center;
      flex-flow: row-reverse;
    }

    .panel-details {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    &.opened {
      position: relative;
      .panel-header {
        &:before {
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;
          top: -100%;
          left: 0;
          bottom: 0;
          background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
          display: block;
          z-index: auto;
        }
      }
    }

    .panel-header {
      background-color: ${({ colorProfile }) => themeUtil(Prop.HEADER_BG, CPNT, colorProfile)};
      top: ${(props) => `${props.top}px`};
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      position: sticky;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      z-index: 10;

      &.open-sector {
        .icon.expand {
          transition : all 0.35s ease-in-out;
          transform: rotate(0deg);
        }
      }

      .panel-icon {
        display: inline-block;
        padding: 10px;

        .icon svg {
          fill: ${({ colorProfile }) => themeUtil(Prop.HEADER_ICON, CPNT, colorProfile)};
          width: 100%;
          height: 100%;
        }
      }

      &:focus {
        outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.HEADER_ICON, CPNT, colorProfile)};
        outline-offset: -4px;
      }
    }

    .panel-header.btn .panel-label {
      color: ${({ colorProfile }) => themeUtil(Prop.HEADER_TXT, CPNT, colorProfile)};
      font-size: ${({ theme }) => theme.FontSize.MED30};
       ${({ theme }) => theme.fonts.PRI_HDR_BLK};
      text-transform: capitalize;
      margin: 0;

      ${Breakpoints.mobile(css`
        font-size: ${({ theme }) => theme.FontSize.MED40};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      `)}
    }
  }


`;

export const StyledDorDrawer = styled.div`
  position: sticky;
  top: ${(props) => `${props.top}px`};
  min-width: 250px;
  margin-bottom: 20px;

  .ui-drawer  {
    height: 100%;
  }

  .ui-drawer-content-wrap {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
  }

  .ui-drawer-content {
    max-height: ${(props) => `calc(100vh - ${parseFloat(props.top) + 35}px)`} ;
    overflow-x: hidden;
  }

  .ui-drawer-btn-toggle {
    font-family: ${({ theme }) => theme.fonts.PRI_HDR_BLK};
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    position: absolute;
    left: 100%;
    padding: 15px;
    transform-origin: bottom left;
    transform: rotate(90deg);
    text-transform: uppercase;
    font-size: 24px;
    line-height: 30px;

    &:hover {
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)} ;
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    }

    &:active {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_ACTIVE, CPNT, colorProfile)};
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_ACTIVE, CPNT, colorProfile)};
    }
  }

  .drawr-fitler-header {
    background-color: ${({ colorProfile }) => themeUtil(Prop.HEADER_BG, CPNT, colorProfile)};
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 18px 10px;
    position: relative;
    z-index: 101;

    h3 {
      color: ${({ colorProfile }) => themeUtil(Prop.HEADER_TXT, CPNT, colorProfile)} !important;
      font-size: 16px;
      text-transform: capitalize;
      margin: 0;

      small {
        color: ${({ colorProfile }) => themeUtil(Prop.HEADER_TXT, CPNT, colorProfile)};
        margin: 0;
      }
    }

    .icon {
      svg {
        fill: ${({ colorProfile }) => themeUtil(Prop.HEADER_ICON, CPNT, colorProfile)};
      }
    }
  }

  .ui-accordion {
    ${Breakpoints.mobile(css`
      padding: 15px;
    `)}
  }

  .ui-accordion-panel {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};

    .checkboxes li {
      margin-bottom: 10px;
      padding-left: 5px;
    }

    &.opened {
      position: relative;
      .panel-header {
        &:before {
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;
          top: -100%;
          left: 0;
          bottom: 0;
          background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
          display: block;
          z-index: auto;
        }
      }
    }

    &:after {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};
      content: "";
      display: block;
      height: 1px;
    }

    &.collapsed::after {
      display: none;
    }

    .panel-render {
      display: flex;
      align-items: center;
    }

    .panel-details {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    .panel-header {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
      border-color: ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid;
      padding: 5px;
      z-index: 10;

      ${Breakpoints.mobile(css`
        top: 0;
      `)}

      &.open-sector {
        .icon.expand {
          transition : all 0.35s ease-in-out;
          transform: rotate(0deg);
        }
      }

      .panel-icon {
        display: inline-block;
        padding: 10px;

        .icon svg {
          fill: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
          width: 100%;
          height: 100%;
        }
      }

      &:focus {
        outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
        outline-offset: -4px;
      }

      &.btn .panel-label {
        color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
        font-size: ${({ theme }) => theme.FontSize.MED30};
        text-transform: uppercase;
        margin: 0;
      }
    }
  }
`;

export const StyledDorFilter = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;

  ${Breakpoints.mobile(css`
    flex-direction: column;
  `)}

  &.layout-row {
    align-items: center;

    ${Breakpoints.mobile(css`
      align-items: flex-start;
    `)}

    h4 {
      margin-bottom: 0;

      ${Breakpoints.mobile(css`
        margin-bottom: 15px;
      `)}

    }
  }

  &.layout-column {
    flex-direction: column;
  }

  &.layout-list {
    flex-direction: column;
    padding: 10px 0;

    .checkboxes {
      flex-direction: column;

      li {
        margin-bottom: 5px;
      }
    }
  }

  h4 {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .icon.extra-small,
  .icon.snowmaking-capable,
  .icon.snowmaking-24-hours,
  .icon.gladed,
  .icon.race {
    svg,
    svg path {
      width: 20px;
      fill:${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
    }
  }

  .icon.snowmaking-24-hours {
    svg {
      width: 18px;
      margin-left: 5px;
    }
  }

  .form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .icon {
      margin-right: 8px;
    }
  }

  ul.checkboxes {
    display: flex;
    flex-wrap: wrap;

    .icon {
      align-items: center;
    }

    li,
    label {
      align-items: center;
      display: flex;
      cursor: pointer;
      position: relative;
    }

    li {
      margin-right: 10px;
    }

    label {
      cursor: pointer;
      position: relative;

      input[type='checkbox'],
      input[type='checkbox']:after,
      input[type='checkbox']:before {
        content: none;
        overflow: hidden;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        outline: none;
      }

      input[type='checkbox']:after,
      input[type='checkbox']:before {
        content: "";
        cursor: pointer;
        top: 3px;
        left: 3px;
        position: absolute;
        border: 1px solid;
        border-radius: 4px;
        border-color: ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};
        margin-right: 7px;
        display: block;
      }

      input[type='checkbox']:before {
        background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
      }

      input[type='checkbox']:checked:after {
        border-color: ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};
        transform: rotate(-57deg);
        border: 0.15625rem solid;
        width: 9px;
        height: 5px;
        top: 9px;
        left: 8px;
        border-top: none;
        border-right: none;
        border-radius: 0;
      }

      input[type="checkbox"]:focus {
        outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};;
        outline-offset: 4px;
      }
    }

  }

  .difficulty {
    .level-black {
      margin-right: 0;
    }
    .level-black-2 {
      width: 32px;
    }
  }

`;

export const StyledSectorDetails = styled.ul`
  text-align: right;

  ${Breakpoints.mobile(css`
    display: none;
  `)}

  li.item {
    color: ${({ colorProfile }) => themeUtil(Prop.HEADER_TXT, CPNT, colorProfile)};
    display: inline-block;
    margin: 0;
    padding: 0;
    margin-right: 10px;

    strong {
      text-transform: capitalize;
      margin-right: 5px;
    }
  }
`;

export const StyledSectorDifficulty = styled.ul`
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
  display: flex;
  margin: 0;
  padding: 0;
  line-height: 0;
  border-radius: 4px;

  li {
    display: flex;
    padding: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const StyledDorLiftsTrails = styled.div`
  margin-bottom: 30px;

  #sector-status-lifts-legend {
    z-index: 140;
    position: relative;
  }

  #sector-all-lifts-accordion.ui-accordion-panel {
    z-index: 135;
    position: relative;
  }

  #sector-status-trails-filters {
    position: relative;
    z-index: 125;
  }

  #sector-trail-types-rating-legend.ui-accordion-panel{
    z-index: 115;
  }

  #sector-trail-types-rating-filters{
    z-index: 110;
  }

  .slider .slick-slide button.play {
    left: 0;
  }

  h3 {
    color: ${({ colorProfile }) => themeUtil(Prop.HEADER_TXT_2, CPNT, colorProfile)};
    font-size: ${({ theme }) => theme.FontSize.MED20};
    font-family: ${({ theme }) => theme.fonts.PRI_BDY_MED};
  }

  h4 {
    font-size: ${({ theme }) => theme.FontSize.MED20};
    padding-left: 0.3125rem;
  }

  td.no-padding {
    padding: 0px;
    display: inline-block;
  }

  .icons {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    gap: 10px;

    .icon {
      margin-bottom: 5px;
    }
  }

  icon.opening,
  icon.closed,
  icon.on-hold,
  icon.expected {}

  icon.groomed-pm,
  icon.groomed-am,
  icon.groomed-recent {
    padding-top: 0;
    width: 35px;
    height: 18px;

    svg {
      width: 35px;
      height: 18px;
    }
  }

  button.tag-btn {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin: 5px;
    border: 1px solid;
    border-color: ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};

    span {
      margin: 5px;
    }
  }

  .legend .status {
    icon.icon {
      margin-right: 8px;
    }
  }

  /* .lift-legend input {
    display: none;
  } */

  .legend {
    border-bottom: 1px solid;
    border-bottom-color: ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;

    h3 {
      margin-bottom: 0;
    }

    .status {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      span {
        display: flex;
        margin: 18px;
        margin-right: 0;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .collapse-td {
    display: none;

    p {
      font-size: 12px;
    }
  }

  .legend-trail-type-rating {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .col {
      width: 33.3333%;
    }

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      background-color: transparent;
      margin-bottom: 20px;

      h4 {
        display: inline-block;
        margin-bottom: 0;
        margin-right: 5px;
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      justify-content: start;
      margin-bottom: 15px;

      span.icon-wrap {
        margin: 5px;
        max-width: 20%;
        text-align: center;
      }

      p {
        margin-top: 5px;
        margin-bottom: 0;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  .dor-lifts-trails-drawr,
  .dor-lifts-trails {
    .row-m-25 {
      margin-left: -25px;
      margin-right: -25px;
    }

    button.accordion-btn {
      font-family: ${({ theme }) => theme.fonts.PRI_HDR_BLK};
      font-size: ${({ theme }) => theme.FontSize.MED20};

      a {
        margin-left: 5px;

        .icon-c svg {
          fill: ${({ colorProfile }) => themeUtil(Prop.HEADER, CPNT, colorProfile)};
        }
      }
    }
  }

  .dor-lifts-trails-details {
    margin-top: 25px;
    margin-bottom: 25px;

    .right {
      padding-left: 22px;
    }
  }

  .left {
    text-align: left;
    padding-right: 22px;
  }

  .icon {
    margin: auto
  }

  strong {
    text-transform: uppercase;
  }

  p {
    display: block;
    margin-bottom: 15px;
    font-size: 16px;
  }

  p.short-description {
    display: none;
  }

  .level-black svg,
  .difficulty-level-black svg,
  .difficulty-level-black-2 svg,
  .difficulty-level-black-pill svg,
  .difficulty-level-black-2-pill svg,
  .level-black-2 svg {
    fill: black;
  }
`;

export const StyledLayoutFilters = styled.div`
  position: relative;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};;
  z-index: 1;

  ${Breakpoints.mobile(css`
    z-index: 70;
  `)}
`;

export const StyledLayoutSectors = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;

  ${Breakpoints.mobile(css`
    width: 100%;
  `)}
`;

export const StyledLayoutWrapper = styled.div`
  display: flex;
  gap: 15px;

  ${Breakpoints.mobile(css`
    display: block;
  `)}
`;

export const StyledLayoutGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  padding: 20px;
`;
