/* eslint-disable import/no-unresolved */
import theme from '@theme';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { Icon, UiHtml } from '@powdr/components';
import { ThemeProps as Prop, ComponentGroups } from '@powdr/constants';
import { themeUtil, componentFontSize } from '@powdr/utils';

const ContentDemoWrapper = styled.div`
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, null, colorProfile)};
  border: 5px solid ${({ colorProfile }) => themeUtil(Prop.BORDER, null, colorProfile)};
  padding: 25px;

  hr {
    border: 1px solid ${({ colorProfile }) => themeUtil(Prop.BORDER, null, colorProfile)};
  }
`;

const Header = styled.h1`
  color: ${({ colorProfile }) => themeUtil(Prop.HEADER, null, colorProfile)};
  ${theme.fonts.PRI_HDR_BLK};
  font-size: ${theme.FontSize.MED30};
  margin-bottom: 5px;
`;

const ContentWrapper = styled.div``;

const SharedContentStyles = css`
  ${theme.fonts.PRI_BDY_MED};
  font-size: ${theme.FontSize.REG40};
  line-height: normal;
`;

const ContentHeader = styled.div`
  ${SharedContentStyles}
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, null, colorProfile)};
  margin-bottom: 5px;
`;
const LinkExampleWrapper = styled.div`
  margin-bottom: 25px;
`;

const LinkExample = styled.a`
  ${SharedContentStyles}
  color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, null, colorProfile)};
  transition: all 0.15s ease-in-out;

  &:hover {
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, null, colorProfile)};
  }
`;

const ContentExample = styled.div`
  ${SharedContentStyles}
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, null, colorProfile)};
  margin-bottom: 25px;

  a {
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, null, colorProfile)};
    transition: all 0.15s ease-in-out;

    &:hover {
      color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, null, colorProfile)};
    }
  }
`;

const BorderDesc = styled.p`
  ${theme.fonts.PRI_BDY_MED};
  font-size: ${theme.FontSize.REG40};
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, null, colorProfile)};
  margin: 10px 0;
  padding: 10px 0;
`;

const SharedButtonStyles = css`
  padding: 16px 20px;
  ${theme.fonts.PRI_BDY_HVY};
  font-size: ${theme.FontSize.REG40};
  line-height: normal;
  margin-bottom: 10px;
  transition: all 0.15s ease-in-out;
`;

const ButtonExamplesContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  margin: 25px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HoverableButton = styled.button`
  ${SharedButtonStyles}
  color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, null, colorProfile)};
  background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, null, colorProfile)};

  &:hover {
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, null, colorProfile)};
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, null, colorProfile)};
  }
`;

const ActiveButton = styled.button`
  ${SharedButtonStyles}
  color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_ACTIVE, null, colorProfile)};
  background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_ACTIVE, null, colorProfile)};
`;

const InactiveButton = styled.button`
  ${SharedButtonStyles}
  color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_INACTIVE, null, colorProfile)};
  background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_INACTIVE, null, colorProfile)};
`;

const ButtonDesc = styled.div`
  ${SharedContentStyles}
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, null, colorProfile)};

  p {
    margin-bottom: 3px;
  }
`;

const IconExamplesContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  margin: 25px 0;
`;

const StyledIcon = styled(Icon)`
  margin-bottom: 10px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.ICON, null, colorProfile)};
    transition: all 0.15s ease-in-out;
  }

  &:hover svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.ICON_HOVER, null, colorProfile)};
  }
`;

const IconDesc = styled.div`
  ${SharedContentStyles}
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, null, colorProfile)};

  p {
    margin-bottom: 3px;
  }
`;

const WysiwigExample = styled.div`
  margin: 25px 0 0 0;

  .wysiwig-example {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .wysiwig-desc-header {
    margin-bottom: 0;
  }

  .example-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 25px;
    width: 100%;
  }

  .example-item {
    margin-bottom: 8px;
  }
`;

export const ColorProfilesDemo = ({
  colorProfile,
}) => {
  const stripText = (text) => text.replace('font-', 'Font ').replace(';', '');
  const iconList = ['ui-arrow-left', 'ui-arrow-right', 'content-snowflake', 'content-sun'];
  const wysiwigExampleContent = `
    <div class="wysiwig-example">
      <h1 class="wysiwig-desc-header">WYSIWIG Content Font Sizes and Examples</h1>
      <p class="wysiwig-desc-header">This applies to the following components: UI HTML, UI Footer, UI Header, Dynamic Content body content</p>
      <div class="example-group">
        <h1 class="example-item">H1 -- ${stripText(componentFontSize(ComponentGroups.WYSIWIG, 'h1'))} -- ${stripText(theme.fonts.PRI_HDR_BLK)}</h1>
        <h2 class="example-item">H2 -- ${stripText(componentFontSize(ComponentGroups.WYSIWIG, 'h2'))} -- ${stripText(theme.fonts.PRI_HDR_BLK)}</h2>
        <h3 class="example-item">H3 -- ${stripText(componentFontSize(ComponentGroups.WYSIWIG, 'h3'))} -- ${stripText(theme.fonts.PRI_HDR_BLK)}</h3>
        <h4 class="example-item">H4 -- ${stripText(componentFontSize(ComponentGroups.WYSIWIG, 'h4'))} -- ${stripText(theme.fonts.PRI_HDR_BLK)}</h4>
        <h5 class="example-item">H5 -- ${stripText(componentFontSize(ComponentGroups.WYSIWIG, 'h5'))} -- ${stripText(theme.fonts.PRI_HDR_BLK)}</h5>
        <h6 class="example-item">H6 -- ${stripText(componentFontSize(ComponentGroups.WYSIWIG, 'h6'))} -- ${stripText(theme.fonts.PRI_HDR_BLK)}</h6>
        <p class="example-item">p -- ${stripText(componentFontSize(ComponentGroups.WYSIWIG, 'content'))} -- ${stripText(theme.fonts.PRI_BDY_MED)} /// Breakpoint at 1600px screen size or higher -- ${componentFontSize(ComponentGroups.WYSIWIG, 'content-large-breakpoint')}</p>
        <ul>
          <li>li -- ${stripText(componentFontSize(ComponentGroups.WYSIWIG, 'content'))} -- ${stripText(theme.fonts.PRI_BDY_MED)}</li>
          <li>li -- ${stripText(componentFontSize(ComponentGroups.WYSIWIG, 'content'))} -- ${stripText(theme.fonts.PRI_BDY_MED)}</li>
          <li>li -- ${stripText(componentFontSize(ComponentGroups.WYSIWIG, 'content'))} -- ${stripText(theme.fonts.PRI_BDY_MED)}</li>
          <li>li -- ${stripText(componentFontSize(ComponentGroups.WYSIWIG, 'content'))} -- ${stripText(theme.fonts.PRI_BDY_MED)}</li>
        </ul>
      </div>
    </div>
  `;

  const IconMap = () => iconList.map((iconName) => (
    <IconContainer key={iconName} colorProfile={colorProfile}>
      <StyledIcon name={iconName} height="50" />
      <IconDesc colorProfile={colorProfile}>
        <p>{`ICON -- ${themeUtil(Prop.ICON, null, colorProfile)}`}</p>
        <p>{`ICON (Hover) -- ${themeUtil(Prop.ICON_HOVER, null, colorProfile)}`}</p>
      </IconDesc>
    </IconContainer>
  ));

  return (
    <ContentDemoWrapper colorProfile={colorProfile}>
      <Header colorProfile={colorProfile}>{`HEADER -- ${themeUtil(Prop.HEADER, null, colorProfile)}`}</Header>
      <ContentWrapper>
        <ContentHeader colorProfile={colorProfile}>
          {`CONTENT -- ${themeUtil(Prop.CONTENT, null, colorProfile)}`}
        </ContentHeader>
        <LinkExampleWrapper>
          <LinkExample colorProfile={colorProfile}>{`LINK TEXT -- ${themeUtil(Prop.LINK_TXT, null, colorProfile)} /// LINK TEXT (Hover) -- ${themeUtil(Prop.LINK_TXT_HOVER, null, colorProfile)}`}</LinkExample>
        </LinkExampleWrapper>
        <ContentExample colorProfile={colorProfile}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit,s sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
          {' '}
          <a href="/#">this is a link here as an example for link and link hover state inside of a block of text</a>
          {' '}
          ut aliquip ex ea commodo consequat.
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
          fugiat nulla pariatur. Excepteur sint occaecat cupidatat
          non proident, sunt in culpa qui of
        </ContentExample>
      </ContentWrapper>
      <hr />
      <BorderDesc colorProfile={colorProfile}>
        {`BORDER -- ${themeUtil(Prop.BORDER, null, colorProfile)} -- The border around this container and the <hr /> divider lines are the border color for the selected color profile (${colorProfile}) `}
      </BorderDesc>
      <hr />
      <ButtonExamplesContainer>
        <ButtonContainer>
          <HoverableButton colorProfile={colorProfile}>Call to Action</HoverableButton>
          <ButtonDesc colorProfile={colorProfile}>
            <p>{`BUTTON BACKGROUND -- ${themeUtil(Prop.BTN_BG, null, colorProfile)}`}</p>
            <p>{`BUTTON TEXT -- ${themeUtil(Prop.BTN_TXT, null, colorProfile)}`}</p>
            <p>{`BUTTON BACKGROUND (Hover) -- ${themeUtil(Prop.BTN_BG_HOVER, null, colorProfile)}`}</p>
            <p>{`BUTTON TEXT (Hover) -- ${themeUtil(Prop.BTN_TXT_HOVER, null, colorProfile)}`}</p>
          </ButtonDesc>
        </ButtonContainer>
        <ButtonContainer>
          <ActiveButton colorProfile={colorProfile}>Active State</ActiveButton>
          <ButtonDesc colorProfile={colorProfile}>
            <p>{`BUTTON BACKGROUND (Active) -- ${themeUtil(Prop.BTN_BG_ACTIVE, null, colorProfile)}`}</p>
            <p>{`BUTTON TEXT (Active) -- ${themeUtil(Prop.BTN_TXT_ACTIVE, null, colorProfile)}`}</p>
          </ButtonDesc>
        </ButtonContainer>
        <ButtonContainer>
          <InactiveButton colorProfile={colorProfile}>Inactive State</InactiveButton>
          <ButtonDesc colorProfile={colorProfile}>
            <p>{`BUTTON BACKGROUND (Inactive) -- ${themeUtil(Prop.BTN_BG_INACTIVE, null, colorProfile)}`}</p>
            <p>{`BUTTON TEXT (Inactive) -- ${themeUtil(Prop.BTN_TXT_INACTIVE, null, colorProfile)}`}</p>
          </ButtonDesc>
        </ButtonContainer>
      </ButtonExamplesContainer>
      <hr />
      <IconExamplesContainer>{IconMap()}</IconExamplesContainer>
      <hr />
      <WysiwigExample colorProfile={colorProfile}>
        <UiHtml html={{ value: wysiwigExampleContent }} parentColorProfile={colorProfile} />
      </WysiwigExample>
    </ContentDemoWrapper>
  );
};

ColorProfilesDemo.propTypes = {
  colorProfile: PropTypes.string.isRequired,
};

ColorProfilesDemo.defaultProps = {

};
