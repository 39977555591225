/* eslint-disable import/no-unresolved */
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'swiper/css/controller';
import 'swiper/css/effect-fade';
import 'swiper/css/effect-cards';
import 'swiper/css/scrollbar';
import 'swiper/css/free-mode';
import 'swiper/css/a11y';
import 'swiper/css/thumbs';

import {
  ThemeProps as Prop, Breakpoints, Components, NavigationDirection, CarouselPaginationPlacement,
  CarouselNavigationPlacement,
  CarouselControlPlacement,
} from '@powdr/constants';
import { rgbaTransparencyToHex, themeUtil } from '@powdr/utils';

const CPNT = Components.CONTENT_CAROUSEL;

export const StyledPrimaryCarousel = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  gap: 50px;

  ${Breakpoints.mobile(css`
    gap: 15px;
  `)}
`;

export const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;

  ${({ $isThumbnailCarousel }) => ($isThumbnailCarousel) && 'padding-top: 10px !important;'};
`;

export const StyledSwiperSlide = styled(SwiperSlide)`
  ${({ $fitSlideWidthToItem }) => (($fitSlideWidthToItem) && 'width: auto !important; height: auto;')};

  ${({ $isThumbnail }) => ($isThumbnail) && `
    cursor: pointer;

    :not(.swiper-slide-thumb-active) {
      opacity: 0.6;
    }
  `}
`;

const OnSlideControlStyles = css`
  height: 100%;
  top: 0;
  pointer-events: none;
  justify-content: space-between;
  position: absolute;
`;

const StandardControlStyles = css`
  ${({ $placement }) => (($placement === CarouselControlPlacement.BOTTOM) ? 'padding-top' : 'padding-bottom')}: 50px;
  ${Breakpoints.mobile(css`
    padding-left: 30px;
    padding-right: 30px;
  `)}
`;

export const CarouselNavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 30px;
  text-align: center;

  ${({ $placement }) => (
    ($placement === CarouselNavigationPlacement.ON_SLIDE)
      ? OnSlideControlStyles
      : StandardControlStyles
  )};
`;

export const FractionPaginationWrapper = styled.div`
  display: flex;
  height: 115px;
  align-items: center;
  justify-content: center;

  ${Breakpoints.mobile(css`
    height: 75px;
    ${({ $paginationPlacement }) => (($paginationPlacement === CarouselPaginationPlacement.ON_SLIDE) ? 'height: fit-content;' : '')}
  `)}

  ${({ $paginationPlacement, $colorProfile }) => (($paginationPlacement === CarouselPaginationPlacement.ON_SLIDE) && `
      position: absolute;
      height: fit-content;
      padding: 15px;
      margin: 10px;
      bottom: 0;
      right: 0;
      z-index: 2;
      background-color: ${themeUtil(Prop.BACKGROUND, CPNT, $colorProfile) + rgbaTransparencyToHex(0.75)};
    `)}
`;

export const FractionPagination = styled.div`
  color: ${({ $colorProfile }) => themeUtil(Prop.CONTENT, CPNT, $colorProfile)};
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.MED40};

  ${Breakpoints.mobile(css`
    ${({ $paginationPlacement, theme }) => (($paginationPlacement === CarouselPaginationPlacement.ON_SLIDE) && `font-size: ${theme.FontSize.REG50};`)}
  `)}
`;

export const BulletPaginationWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 15px;
`;

export const BulletPagination = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${({ $smallGap }) => (($smallGap) ? 15 : 30)}px;
`;

export const SinglePaginationBullet = styled.li`
  cursor: pointer;

  .standard-bullet {
    height: 15px;
    width: 15px;
    background-color: ${({ $colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, $colorProfile)};
    border-radius: 50%;
    transition: all 0.25s ease-out;
  }

  .icon-bullet svg {
    transition: all 0.25s ease-out;
    fill: ${({ $colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, $colorProfile)};
  }

  .text-bullet {
    ${({ theme }) => theme.fonts.PRI_BDY_HVY};
    font-weight: bold;
    font-size: ${({ theme }) => theme.FontSize.MED50};
    text-transform: uppercase;
    color: ${({ $colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, $colorProfile)};
    line-height: 1.1;
    transition: all 0.25s ease-out;

    ${Breakpoints.mobile(css`
      font-size: ${({ theme }) => theme.FontSize.MED20};
    `)}
  }

  &.active, &:focus, &:hover {
    .text-bullet {
      transition: all 0.25s ease-out;
      color: ${({ $colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, $colorProfile)};
    }

    .icon-bullet svg {
      transition: all 0.25s ease-out;
      fill: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    }

    .standard-bullet {
      transition: all 0.25s ease-out;
      background-color: ${({ $colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, $colorProfile)};
    }
  }

`;

export const ControlArrowWrapper = styled.button`
  cursor: pointer;

  // arrows positioned on slide
  ${({ $placement, direction, $colorProfile }) => (($placement === CarouselPaginationPlacement.ON_SLIDE) && `
    width: 35px;
    height: 100%;
    pointer-events: all;

    &:hover, &:focus {
      .control-arrow {
        transition: border-color 0.25s ease-out;
        &:after {
          border: solid ${themeUtil(Prop.BTN_BG_HOVER, CPNT, $colorProfile)};
          border-width: 35px;
          border-top: 35px solid transparent;
          border-bottom: 35px solid transparent;
          ${(direction === NavigationDirection.PREVIOUS) ? 'border-right' : 'border-left'}: none;
        }

        .control-arrow-icon {
          svg {
            fill: ${themeUtil(Prop.BTN_TXT_HOVER, CPNT, $colorProfile)};
          }
        }
      }
    }`
  )}
`;

const OnSlideArrowStyle = css`
  position: absolute;
  top: 50%;
  ${({ direction }) => ((direction === NavigationDirection.PREVIOUS) ? 'left' : 'right')}: 0;

  &:after {
    content: " ";
    position: absolute;
    top: calc(50% - 35px);
    ${({ direction }) => ((direction === NavigationDirection.PREVIOUS) ? 'left' : 'right')}: 0;
    border: solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
    border-width: 35px;
    border-top: 35px solid transparent;
    border-bottom: 35px solid transparent;
    ${({ direction }) => ((direction === NavigationDirection.PREVIOUS) ? 'border-right' : 'border-left')}: none;
    width: 0;
    height: 0;
    z-index: 2;
  }

  .control-arrow-icon {
    position: relative;
    z-index: 3;
    ${({ direction }) => ((direction === NavigationDirection.PREVIOUS) ? 'left: 5px;' : 'right: 5px; transform: rotate(180deg);')};

    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    }
  }
`;

const StandaloneArrowStyle = css`
  ${({ direction }) => ((direction !== NavigationDirection.PREVIOUS) && 'transform: rotate(-180deg);')};
  transition: all 0.25s ease-out;

  .control-arrow-icon {
    svg {
      transition: all 0.25s ease-out;
      fill: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
    }
  }


  &:hover, &:focus {
    .control-arrow-icon {
      svg {
        transition: all 0.25s ease-out;
        fill: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
      }
    }
  }
`;

export const ControlArrow = styled.div`
  ${({ $placement }) => (($placement === CarouselControlPlacement.ON_SLIDE) ? OnSlideArrowStyle : StandaloneArrowStyle)};
`;

export const ScrollbarContainer = styled.div``;

export const ScrollbarElement = styled.div`
  --swiper-scrollbar-sides-offset: 5%;
  --swiper-scrollbar-drag-bg-color: ${({ $colorProfile }) => themeUtil(Prop.BORDER, CPNT, $colorProfile)};
  position: relative;
  width: calc(100% - 2 * var(--swiper-scrollbar-sides-offset, 1%));
  height: 8px;
  border-radius: 10px;
  background-color: ${({ $colorProfile }) => themeUtil(Prop.BORDER, CPNT, $colorProfile) + rgbaTransparencyToHex(0.25)};
`;
