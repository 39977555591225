import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';

import { Icon, VimeoVideo } from '@powdr/components';
import { HeaderStyles } from '@powdr/constants';
import { AppContext } from '@powdr/context/app-context';
import { useSeason } from '@powdr/hooks';
import { colorProfileByIndex, getImageAltText } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import { OverlayImage } from './components';
import {
  StyledHeroSlide, BackgroundImage, BackgroundVideo,
  SlideContent, SlideContentWrapper,
  HeroIconWrapper, CopyHeader, CopyContent, SlideButton,
} from './styles';

export const HeroSlide = ({
  slide: {
    id,
    title,
    copy,
    isDisableTextShadow,
    copyBackgroundColor,
    buttonText,
    buttonHref,
    buttonTarget,
    vimeoLink,
    vimeoLinkMobile,
    contentAlignment,
    heroIcon,
    heroIconWidth,
    componentSeason,
    accent,
    imageWidth,
    imageWidthMobile,
    backgroundVideoLink,
    backgroundVideoLinkMobile,
    relationships,
  },
  isEnableMobileVideoAutoplay,
  slideOffsetDirection,
  slideOffsetAdjustment,
  isAltCopyDisplay,
}) => {
  const { isMobile, themeConfig } = useContext(AppContext);
  const { headerStyle } = useStaticData();
  const { checkSeasonality } = useSeason();
  const slideRef = useRef(null);
  const headerStyleContentAdjustment = (isMobile || headerStyle !== HeaderStyles.TRANSLUCENT)
    ? 0
    : themeConfig.layout.headerFeedContainer;
  const { gatsbyImage: image } = relationships?.primaryImage?.relationships?.image || {};
  const { gatsbyImage: mobileImage } = relationships?.mobileImage?.relationships?.image || {};
  const hasOverlayImage = (relationships?.overlayImage?.gatsbyImage
    || relationships?.overlayImage?.imageSrc);
  const copyBackgroundColorValue = copyBackgroundColor
    ? colorProfileByIndex(copyBackgroundColor)
    : null;

  return (
    checkSeasonality(componentSeason) && (
      <StyledHeroSlide key={id} ref={slideRef} isVimeoBackground={!!(vimeoLink || vimeoLinkMobile)}>
        <BackgroundImage
          image={(isMobile && mobileImage) ? mobileImage : image}
          loading="eager"
          alt={getImageAltText(
            (isMobile && mobileImage)
              ? relationships?.mobileImage?.relationships?.image
              : relationships?.primaryImage?.relationships?.image,
          )}
          $slideOffsetDirection={slideOffsetDirection}
          $slideOffsetAdjustment={slideOffsetAdjustment}
          $isAltCopyDisplay={(isMobile) ? false : isAltCopyDisplay}
        />
        <BackgroundVideo
          $slideOffsetDirection={slideOffsetDirection}
          $slideOffsetAdjustment={slideOffsetAdjustment}
          $isAltCopyDisplay={(isMobile) ? false : isAltCopyDisplay}
        >
          {(!vimeoLink && !vimeoLinkMobile
              && ((!isMobile && backgroundVideoLink)
              || (isMobile && (backgroundVideoLinkMobile
              || (backgroundVideoLink && isEnableMobileVideoAutoplay))))
          ) && (
          <video autoPlay loop muted playsInline>
            <source
              src={(isMobile && backgroundVideoLinkMobile)
                ? backgroundVideoLinkMobile
                : backgroundVideoLink}
            />
          </video>
          )}
          {(vimeoLink || vimeoLinkMobile) && (
            <VimeoVideo
              parentRef={slideRef}
              videoLink={(isMobile && vimeoLinkMobile) ? vimeoLinkMobile : vimeoLink}
              isBackground
            />
          )}
        </BackgroundVideo>
        <SlideContentWrapper
          $slideOffsetDirection={slideOffsetDirection}
          $slideOffsetAdjustment={slideOffsetAdjustment}
          headerStyleContentAdjustment={headerStyleContentAdjustment}
          hasOverlayImage={hasOverlayImage}
          $isAltCopyDisplay={(isMobile) ? false : isAltCopyDisplay}
          contentAlignment={contentAlignment}
          isMobile={isMobile}
        >
          {(!hasOverlayImage) ? (
            <SlideContent
              className={copyBackgroundColor}
              $isAltCopyDisplay={(isMobile) ? false : isAltCopyDisplay}
              headerStyleContentAdjustment={headerStyleContentAdjustment}
              contentAlignment={contentAlignment}
            >
              {(heroIcon) && (
              <HeroIconWrapper>
                <Icon name={heroIcon} width={heroIconWidth || 50} />
              </HeroIconWrapper>
              )}
              {(title) && (
                <CopyHeader
                  className={classNames('animated fadeInUp')}
                  $colorProfile={copyBackgroundColorValue}
                  $isDisableTextShadow={isDisableTextShadow}
                  $isDisablePadding={!(copy)}
                >
                  {title}
                </CopyHeader>
              )}
              {(copy) && (
                <CopyContent
                  className={classNames('animated fadeInUp')}
                  $colorProfile={copyBackgroundColorValue}
                  $isDisableTextShadow={isDisableTextShadow}
                >
                  {copy}
                </CopyContent>
              )}
              {(buttonText) && (
              <SlideButton href={buttonHref} target={buttonTarget}>
                {buttonText}
              </SlideButton>
              )}
              {(!isMobile && accent) && <div className="accent">{accent}</div>}
            </SlideContent>
          ) : (
            <OverlayImage
              imageData={relationships.overlayImage}
              imageWidth={imageWidth}
              imageWidthMobile={imageWidthMobile}
              href={buttonHref}
              target={buttonTarget}
            />
          )}
        </SlideContentWrapper>
      </StyledHeroSlide>
    )
  );
};

HeroSlide.propTypes = {
  slide: PropTypes.shape().isRequired,
  isEnableMobileVideoAutoplay: PropTypes.bool,
  slideOffsetDirection: PropTypes.string,
  slideOffsetAdjustment: PropTypes.number,
  isAltCopyDisplay: PropTypes.bool,
};

HeroSlide.defaultProps = {
  isEnableMobileVideoAutoplay: false,
  slideOffsetDirection: null,
  slideOffsetAdjustment: null,
  isAltCopyDisplay: false,
};
