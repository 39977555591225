import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { Alignment } from '@powdr/constants';
import { fixCmsLinks, globalContentParser } from '@powdr/utils';

import { StyledUiHtml, StyledIcon } from './styles';

export const UiHtml = ({
  html,
  isHideComponent,
  iconName,
  parentColorProfile,
  alignment,
}) => {
  const content = useMemo(() => (fixCmsLinks(html?.value)), [html]);

  return (
    (!isHideComponent) && (
      <StyledUiHtml className="ui-html" colorProfile={parentColorProfile} $alignment={alignment}>
        {iconName && (<StyledIcon name={iconName} width="50" height="50" />)}
        {globalContentParser(content)}
      </StyledUiHtml>
    )
  );
};

UiHtml.propTypes = {
  html: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  isHideComponent: PropTypes.bool,
  iconName: PropTypes.string,
  parentColorProfile: PropTypes.string,
  alignment: PropTypes.string,
};

UiHtml.defaultProps = {
  isHideComponent: false,
  iconName: '',
  parentColorProfile: null,
  alignment: Alignment.LEFT,
};
