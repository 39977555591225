import styled, { css } from 'styled-components';

import { ThemeProps as Prop, Components, Breakpoints } from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.HOTSPOT_GROUP;

export const StyledHotspotModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: 1/1;
  z-index: 2;
  position: relative;
  margin: 100px 50px;
  padding: 30px;
  border-radius: 30px;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
  overflow: hidden;

  ${Breakpoints.mobile(css`
    padding: 0;
    margin: 15px;
  `)}
`;

export const BlockContainer = styled.div`
  padding: 30px 15px;
  height: 100%;
  width: 100%;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 30px;
  right: 30px;
  padding: 15px;
  border-radius: 50%;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
  transition: fill 0.25s ease-in-out, background-color 0.25s ease-in-out;
  z-index: 3;

  ${Breakpoints.mobile(css`
    top: 15px;
    right: 15px;
  `)}

  svg {
    transition: fill 0.25s ease-in-out, background-color 0.25s ease-in-out;
    fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
  }

  &:hover, &:focus {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};

    svg {
      transition: fill 0.25s ease-in-out, background-color 0.25s ease-in-out;
      fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
    }
  }

  &:focus {
    outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    outline-offset: 2px;
  }
`;
