/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

import { Icon, MegaContainer, Link } from '@powdr/components';
import {
  ThemeProps as Prop, Components, Breakpoints, NavigationStyles,
} from '@powdr/constants';
import { componentColor, themeUtil } from '@powdr/utils';

const CPNT_DESKTOP = Components.MEGA_NAV;
const CPNT_MOBILE = Components.MOBILE_NAV;

export const StyledMegaNav = styled.div`
  ${Breakpoints.desktopOnly(css`
    a.cams {
      span,
      img {
        display: block;
      }
    }
  `)}

  .message-banners {
    margin: 0;
    padding: 0;
    background-color: ${() => themeUtil(Prop.BACKGROUND, CPNT_DESKTOP)};

    a.message {
      color: ${() => themeUtil(Prop.BTN_TXT, CPNT_DESKTOP)};
      ${({ theme }) => theme.fonts.SEC_HDR_BLK};
      font-size: ${({ theme }) => theme.FontSize.REG30};
      padding: 10px;
      margin: 0;
      text-align: center;

      ${Breakpoints.large(css`
        font-size: ${({ theme }) => theme.FontSize.REG40};
      `)}

      ${Breakpoints.larger(css`
        font-size: ${({ theme }) => theme.FontSize.REG50};
      `)}

      p {
        color: inherit;
        margin-bottom: 0;
      }

      &:hover {
        color: ${() => themeUtil(Prop.BTN_TXT_HOVER, CPNT_DESKTOP)};

        p {
          color: inherit;
        }
      }
    }
  }
`;

export const StyledMegaContainer = styled(MegaContainer)`
  ${Breakpoints.desktopOnly(css`
    ${({ numGroups, navigationStyle }) => (
    (navigationStyle !== NavigationStyles.ITEM_WIDTH)
      ? (numGroups < 5)
        ? 'padding-left: 5%; padding-right: 5%;'
        : 'padding-left: 10%; padding-right: 10%;'
      : '')}
  `)}
`;

export const LinkContainer = styled.div`
  width: 100%;

  ${Breakpoints.desktopOnly(css`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: ${({ navigationStyle }) => ((navigationStyle !== NavigationStyles.ITEM_WIDTH) ? 'row' : 'column')};
  `)}
`;

export const LevelTwoLinkGroup = styled.div`
  height: 100%;

  ${Breakpoints.desktopOnly(css`
    ${({ navigationStyle }) => ((navigationStyle !== NavigationStyles.ITEM_WIDTH) ? `border-left: 1px solid ${componentColor(Prop.LINK_TXT, CPNT_DESKTOP, Prop.BORDER)};` : '')}
    margin-bottom: 20px;
    padding: 0 25px;
    width: ${({ numGroups, navigationStyle }) => (
    (navigationStyle !== NavigationStyles.ITEM_WIDTH)
      ? (numGroups <= 6)
        ? `${(100 / numGroups)}%`
        : `${(100 / 5)}%`
      : '100%')};
  `)}

  ${Breakpoints.large(css`
    padding: 0 20px;
  `)}

  ${Breakpoints.small(css`
    padding: 0 17px;
  `)}

  ${Breakpoints.mobile(css`
    padding: 0;
  `)}
`;

export const SingleLevelTwoItem = styled.h3`
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.MED10};

  ${Breakpoints.desktopOnly(css`
    margin-bottom: 15px;
  `)}

  ${Breakpoints.small(css`
    font-size: ${({ theme }) => theme.FontSize.REG50};
  `)}

  ${Breakpoints.large(css`
    font-size: ${({ theme }) => theme.FontSize.MED10};
  `)}

  ${Breakpoints.larger(css`
    font-size: ${({ theme }) => theme.FontSize.MED20};
  `)}
`;

export const LevelTwoLink = styled(Link)`
  display: block;
  color: ${() => componentColor(Prop.LINK_TXT, CPNT_DESKTOP, Prop.CONTENT)};

  &:hover {
    color: ${() => componentColor(Prop.LINK_TXT_HOVER, CPNT_DESKTOP, Prop.LINK_TXT)};
  }

  &:focus {
    outline: 2px solid ${() => componentColor(Prop.LINK_TXT_HOVER, CPNT_DESKTOP, Prop.LINK_TXT_HOVER)};
    outline-offset: 2px;
  }

  ${Breakpoints.mobile(css`
    ${({ theme }) => theme.fonts.PRI_BDY_HVY};
    font-size: ${({ theme }) => theme.FontSize.REG30};
    text-transform: capitalize;
    text-indent: 20px;
    display: flex;
    margin: 0;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    height: 55px;
    width: 100%;
    color: ${() => componentColor(Prop.SUB_NAV_TXT, CPNT_MOBILE, Prop.BTN_TXT)};
    background-color: ${() => componentColor(Prop.SUB_NAV_BG, CPNT_MOBILE, Prop.BTN_BG)};
    border-bottom: 1px solid ${() => componentColor(Prop.SUB_NAV_BORDER, CPNT_MOBILE, Prop.BORDER)};
  `)}
`;

export const NavigationWidgets = styled.div`
  margin-bottom: 10px;
`;

export const LevelThreeLinkGroup = styled.ul``;

export const SingleLevelThreeItem = styled.li`
  ${({ theme }) => theme.fonts.PRI_BDY_MED};
  font-size: ${({ theme }) => theme.FontSize.REG50};

  ${Breakpoints.desktopOnly(css`
    line-height: 18px;
    margin-bottom: 10px;
  `)}

  ${Breakpoints.large(css`
    font-size: ${({ theme }) => theme.FontSize.REG40};
  `)}

  ${Breakpoints.small(css`
    font-size: ${({ theme }) => theme.FontSize.REG30};
  `)}
`;

export const LevelThreeLink = styled(Link)`
  display: block;
  color: ${() => componentColor(Prop.LINK_TXT, CPNT_DESKTOP, Prop.CONTENT)};

  &:hover, &.active {
    color: ${() => componentColor(Prop.LINK_TXT_HOVER, CPNT_DESKTOP, Prop.LINK_TXT)};
  }

  &:focus {
    outline: 2px solid ${() => componentColor(Prop.LINK_TXT_HOVER, CPNT_DESKTOP, Prop.LINK_TXT)};
    outline-offset: 2px;
  }
`;

export const MobileNavToggle = styled.button`
  display: flex;

  h3 & {
    ${({ theme }) => theme.fonts.PRI_BDY_HVY};
    font-size: ${({ theme }) => theme.FontSize.REG30};
    text-indent: 20px;
    display: flex;
    margin: 0;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    height: 55px;
    width: 100%;
    color: ${() => componentColor(Prop.SUB_NAV_TXT, CPNT_MOBILE, Prop.BTN_TXT)};
    background-color: ${() => componentColor(Prop.SUB_NAV_BG, CPNT_MOBILE, Prop.BTN_BG)};
    border-bottom: 1px solid ${() => componentColor(Prop.SUB_NAV_BORDER, CPNT_MOBILE, Prop.BORDER)};

    svg {
      fill: ${() => componentColor(Prop.SUB_NAV_TXT, CPNT_MOBILE, Prop.BTN_TXT)};
    }
  }
`;

export const MobileNavToggleIcon = styled(Icon)`
  transform: rotate(-90deg);
`;

export const MobileLevelThreeDrawer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 22;
  background-color: ${() => themeUtil(Prop.BACKGROUND, CPNT_DESKTOP)};
  transform: translateX(100%);
  transition: transform 0.5s ease;

  ${({ isMobileThirdLevelOpen }) => isMobileThirdLevelOpen && css`
    transform: translateX(0);
  `}
`;

export const LevelThreeNavigation = styled.div`
  margin-top: 50%;

  ul {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  ul > li {
    width: 100%;
    height: auto;

    a {
      display: block;
      padding: 15px 30px 15px 50px;
      font-size: ${({ theme }) => theme.FontSize.REG30};
      ${({ theme }) => theme.fonts.SEC_HDR_BLK};
    }
  }
`;

export const LevelThreeDrawerCloseButton = styled.button`
  display: flex;
  align-items: center;
  padding: 15px;
  height: fit-content;
  width: 100%;
`;

export const CloseButtonIcon = styled(Icon)`
  transform: rotate(90deg);
  margin-right: 7px;

  svg {
    fill: ${() => themeUtil(Prop.ICON, CPNT_DESKTOP)};
  }
`;

export const CloseButtonText = styled.div`
  ${({ theme }) => theme.fonts.SEC_HDR_BLK};
  color: ${() => themeUtil(Prop.CONTENT, CPNT_DESKTOP)};
  font-size: ${({ theme }) => theme.FontSize.MED40};
  text-transform: uppercase;
  text-align: left;
`;
