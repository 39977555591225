// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { Icon } from '@powdr/components';
import { getVideoLink } from '@powdr/utils';

import {
  StyledUiEmbed, AlignmentContainer, BlurBackground,
  CloseButton, PlayButton,
} from './styles';

export const UiEmbed = ({
  videoId,
  videoLink,
  disabled: disableEmbed,
}) => {
  const [isVideoOpen, setVideoOpen] = useState(false);
  const embedLink = getVideoLink(videoLink, videoId, false);

  useEffect(() => {
    if (disableEmbed) setVideoOpen(false);
  }, [disableEmbed]);

  return (
    <StyledUiEmbed className="ui-embed fit">
      <AlignmentContainer>
        {(isVideoOpen && !disableEmbed) && (
          <div className="fit">
            <BlurBackground />
            <iframe title="embed-video" className="fit" src={embedLink} allow="autoplay" allowFullScreen />
          </div>
        )}
        {(!isVideoOpen) && (
          <PlayButton onClick={() => setVideoOpen((!disableEmbed) && true)}>
            <Icon name="content-play" />
          </PlayButton>
        )}
        {(isVideoOpen && !disableEmbed) && (
          <CloseButton onClick={() => setVideoOpen(false)}>
            <Icon className="close-button-icon" name="ui-close" width="15" height="15" />
          </CloseButton>
        )}
      </AlignmentContainer>
    </StyledUiEmbed>
  );
};

UiEmbed.propTypes = {
  videoId: PropTypes.string,
  videoLink: PropTypes.string,
  disabled: PropTypes.bool,
};

UiEmbed.defaultProps = {
  videoId: '',
  videoLink: '',
  disabled: false,
};
