import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';

import { PrimaryCarousel } from '@powdr/components';
import { ThemeProps as Prop, Breakpoints, Components } from '@powdr/constants';
import { themeUtil, rgbaTransparencyToHex } from '@powdr/utils';

const CPNT = Components.IMAGE_GRID;

export const StyledImageGrid = styled.div`
  position: relative;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  padding: 0 7.5%;
  max-height: 850px;

  ${Breakpoints.mobile(css`
    display: none;
  `)}
`;

export const MobileCarousel = styled(PrimaryCarousel)`
  ${Breakpoints.desktopOnly(css`
    display: none;
  `)}
`;

export const ImageContainer = styled.div`
  position: relative;
  min-width: 0;
  min-height: 0;

  &.large-image { grid-area: 1 / 1 / 3 / 2; }
  &.small-image-1 { grid-area: 1 / 2 / 2 / 3; }
  &.small-image-2 { grid-area: 1 / 3 / 2 / 4; }
  &.small-image-3 { grid-area: 2 / 2 / 3 / 3; }
  &.small-image-4 { grid-area: 2 / 3 / 3 / 4; }

  &:hover {
    .image-overlay {
      transition: all 0.25s ease-in-out;
      opacity: 1;
      cursor: pointer;
    }
  }
`;

export const ImageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: ${() => `#000000${rgbaTransparencyToHex(0.15)}`};
  opacity: 0;
`;

export const Image = styled(GatsbyImage)`
  display: block;
  width: 100%;
  height: 100%;
`;

export const ImageCarousel = styled(PrimaryCarousel)`
  height: 100vh;
`;

export const ImageAlignmentWrapper = styled.div`
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
  position: relative;
  height: 100%;
  width: 100%;
`;

export const ViewAllButton = styled.button`
  position: absolute;
  bottom: 25px;
  right: 25px;
  z-index: 2;
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.REG50};
  font-weight: bold;
  padding: 0;
  color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
  padding: 12px 16px;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
  width: 175px;

  &:hover {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};

    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
    }
  }

  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
  }

  ${Breakpoints.mobile(css`
    display: none;
  `)}
`;
