import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components';
import {
  ThemeProps as Prop, Breakpoints, ColorProfiles, Components,
} from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.HOTSPOT_GROUP;

export const StyledHotspotImage = styled.div`
  display: grid;
`;

export const Image = styled(GatsbyImage)`
  grid-area: 1/1;
`;

export const HotspotsContainer = styled.div`
  position: relative;
  grid-area: 1/1;
  height: 100%;
  width: 100%;
`;

export const HotspotListContainer = styled.div`
  position: relative;
  grid-area: 1/1;
`;

export const HotspotList = styled.ul`
  width: 100%;
  height: 100%;
  margin-top: 10px;
`;

export const HotspotListItem = styled.li`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  padding: 0 15px;
  width: 50%;
`;

export const ItemTitle = styled.p`
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  font-size: ${({ theme }) => theme.FontSize.REG50};
  line-height: 1;
  margin-bottom: 10px;
  text-align: center;
`;

export const ItemIcon = styled(Icon)`
  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
  }
`;

export const Hotspot = styled.button`
  position: absolute;
  cursor: pointer;
  width: fit-content;
  inset: ${({ iconVerticalCoordinate, iconHorizontalCoordinate }) => `${iconVerticalCoordinate}% auto auto ${iconHorizontalCoordinate}%`};
  height: ${({ height }) => `${height}px`};
  z-index: 1;
  transition: transform 0.35s ease-in-out, fill 0.35s ease-in-out;

  &:hover, &:focus {
    transition: transform 0.35s ease-in-out, fill 0.35s ease-in-out;
    transform: scale(1.5);

    svg {
      transition: fill 0.35s ease-in-out;
      fill: ${({ colorProfile }) => themeUtil(Prop.ICON_HOVER, CPNT, colorProfile)};
    }
  }

  &:focus {
    outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.ICON_HOVER, CPNT, colorProfile)};
    outline-offset: 2px;
  }
`;

export const HotspotIcon = styled(Icon)`
  height: ${({ height }) => `${height}px`};

  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
  }
`;

export const ActiveHotspotModal = styled.div`
  position: relative;
  grid-area: 1/1;
  height: 100%;
  width: 100%;
`;
