import { mergeDeepLeft } from 'ramda';

import {
  FontSize as FontSizes, FontFamilies,
} from '@powdr/constants';
/** ***************************************** */
/* Default Font Overrides */
/** ***************************************** */
const FontSizeOverrides = {
  REG30: '16px',
  REG40: '17px',
  REG50: '20px',
};

const FontSize = mergeDeepLeft(FontSizeOverrides, FontSizes);

const fonts = {
  [FontFamilies.PRI_HDR_BLK]: 'font-family: "ChollaSlabBold", "Times New Roman"',
  [FontFamilies.SEC_HDR_BLK]: 'font-family: "ChollaSlabRegularMedium", "Times New Roman"',
  [FontFamilies.PRI_BDY_HVY]: 'font-family: "vendettabold", sans-serif',
  [FontFamilies.PRI_BDY_MED]: 'font-family: "vendettamedium", sans-serif',
};

export const fontTheme = {
  fonts,
  FontSize,
};
