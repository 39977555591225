/* eslint-disable import/no-unresolved */
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/a11y';
import 'swiper/css/parallax';
import 'swiper/css/controller';
import 'swiper/css/effect-fade';

import { Icon } from '@powdr/components';
import {
  ThemeProps as Prop, Breakpoints, FontSize, NavigationDirection,
  Components,
} from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.BANNER_CAROUSEL;

export const StyledBannerCarousel = styled.section`
  display: flex;
  flex-direction: column;
  height: ${({ $componentHeight }) => $componentHeight}px;
  width: 100%;
`;

export const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;
`;

export const StyledSwiperSlide = styled(SwiperSlide)`

`;

export const SlideWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
`;

export const BackgroundImage = styled(GatsbyImage)`
  grid-area: 1/1;
  height: 100%;
  width: 100%;
  z-index: -1;
`;

export const ContentCarouselWrapper = styled.div`
  display: flex;
  gap: 10px;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const StaticCarouselComponents = styled.div`
  position: absolute;
  width: 300px;
  z-index: 2;
  margin: 15px;
  max-height: 150px;
`;

export const Title = styled.h1`
  font-size: ${FontSize.REG30};
  margin-bottom: 15px;
  color: ${({ $colorProfile }) => themeUtil(Prop.CONTENT, CPNT, $colorProfile)};
`;

export const SlideContent = styled.p`
  height: 100%;
  line-height: 1.1;
  font-size: ${FontSize.LRG12};
  ${({ theme }) => theme.fonts.PRI_BDY_HVY};
  color: ${({ $colorProfile }) => themeUtil(Prop.CONTENT, CPNT, $colorProfile)};
`;

export const SlideIndex = styled.span`
  font-size: ${FontSize.REG40};
`;

export const ControlArrowWrapper = styled.button`
  ${({ direction }) => ((direction !== NavigationDirection.PREVIOUS) && 'transform: rotate(-180deg);')};
`;

export const ControlArrow = styled.div``;

export const ControlArrowIcon = styled(Icon)`
  width: 25px;
  height: 25px;

  svg {
    fill: ${({ $colorProfile }) => themeUtil(Prop.CONTENT, CPNT, $colorProfile)};
  }
`;
