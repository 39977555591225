import PropTypes from 'prop-types';
import React from 'react';

import { globalContentParser } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import { StyledJuicerFeed } from './styles';

export const JuicerFeed = ({ className }) => {
  const { property } = useStaticData();
  const content = `
    <script src="https://assets.juicer.io/embed.js" type="text/javascript"></script>
    <link href="https://assets.juicer.io/embed.css" media="all" rel="stylesheet" type="text/css"></link>
    <ul class="juicer-feed" data-feed-id=${property} data-origin="embed-code"></ul>
  `;

  return (
    <StyledJuicerFeed className={className}>
      {globalContentParser(content)}
    </StyledJuicerFeed>
  );
};

JuicerFeed.propTypes = {
  className: PropTypes.string,
};

JuicerFeed.defaultProps = {
  className: '',
};
